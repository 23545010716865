import styled from 'styled-components'
import { tabletScreen } from '../../types/media'
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
`
export const ConnectWrapper = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 32px 24px;
  height: 100%;
  width: 450px;
  max-height: 315px;
  background: #fff;
  border-radius: 32px;
  gap: 20px;
`
export const WalletItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 85px;
  border-radius: 16px;
  padding: 24px;
  border: 1px solid #ECEEF0;
  cursor: pointer;
`
export const WalletIcon = styled.img`
  height: 38px;
  width: 38px;
`
export const GreenCircleDecoration = styled.div`
  position: absolute;
  z-index: 1;
  top: 106px;
  left: 125px;
  width: 468px;
  height: 600px;
  background: #CFFE56;
  filter: blur(400px);
  pointer-events: none;

  @media (max-width: ${tabletScreen}) {
    left: 0%;
    width: 320px;
  }
`
export const RedCircleDecoration = styled.div`
  position: absolute;
  z-index: 1;
  right: 165px;
  bottom: 0;
  width: 371px;
  height: 247px;
  background: #FA531C;
  filter: blur(400px);
  pointer-events: none;

  @media (max-width: ${tabletScreen}) {
    right: 0%;
    width: 320px;
  }
`
