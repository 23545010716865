import React, { ReactNode } from 'react'
import { ModalOverlay, Wrapper } from './styled'

type ModalProps = {
  children: ReactNode
}

const GenericModal = ({ children }: ModalProps) => {
  return (
    <ModalOverlay>
      <Wrapper onClick={e => e.stopPropagation()}>{children}</Wrapper>
    </ModalOverlay>
  )
}

export default GenericModal
