import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Onboarding from './widgets/Onboarding'
import ConnectWallet from './widgets/ConnectWallet'
import Main from './widgets/Main'
import FinancialNFT from './widgets/FinancialNFT'
import Dashboard from './widgets/Dashboard'

function App() {
  return (
    <Routes>
      <Route path={'/'} element={<Main />} />
      <Route path={'/financial-nft'} element={<FinancialNFT />} />
      <Route path={'/about'} element={<Onboarding />} />
      <Route path={'/connect'} element={<ConnectWallet />} />
      <Route path={'/dashboard'} element={<Dashboard />} />
    </Routes>
  )
}

export default App
