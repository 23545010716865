import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  width: 370px;
  height: max-content;
  max-height: 375px;;
`

export const ContentWrapper = styled.div<{isGreenBg: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 185px;
  background: ${p => p.isGreenBg ? '#CFFE56' : '#E6E8EB'};
  border-radius: 20px;
  padding: 16px;
  transition: all 0.3s ease;
`
