import React from 'react'
import CustomDot from './Dot'
import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  LineChart,
  Line,
} from 'recharts'

type APYChart<T> = {
  data: T[]
}
const APYChart = <T,>({ data }: APYChart<T>) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="1 1" />
        <XAxis dataKey="name" />
        <YAxis />
        <Line
          dataKey="uv"
          stroke="#978FED"
          strokeWidth={4}
          dot={<CustomDot type={'sell'} />}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default APYChart
