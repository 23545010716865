import styled from 'styled-components'

export const BitcoinIconWrapper = styled.div`
  position: absolute;
  top: -90px;
  left: 42px;
  z-index: 1;
`
export const EthereumIconWrapper = styled.div`
  position: absolute;
  right: -83px;
  top: -6px;
  z-index: 1;
`
export const HeartIconWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: 250px;
  z-index: 1;
`
export const EuroIconWrapper = styled.div`
  position: absolute;
  right: 60px;
  top: 220px;
  z-index: 1;
`
