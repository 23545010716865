import React from 'react'

const AccountIcon = () => {
  return (
    <svg width='40' height='41' viewBox='0 0 40 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_99_4842)'>
        <g filter='url(#filter0_dd_99_4842)'>
          <circle cx='20' cy='20.7334' r='20' fill='#F8F8F8' />
        </g>
        <g opacity='0.8' filter='url(#filter1_f_99_4842)'>
          <ellipse cx='19.7664' cy='19.5209' rx='19.7664' ry='19.5209'
                   transform='matrix(-0.288205 -0.957569 0.936518 -0.35062 6.92371 34.6035)' fill='#FFE073' />
        </g>
        <g opacity='0.8' filter='url(#filter2_f_99_4842)'>
          <ellipse cx='16.7726' cy='16.2469' rx='16.7726' ry='16.2469'
                   transform='matrix(-0.482888 -0.875682 0.798496 -0.602 4.72986 37.75)' fill='#FFC700' />
        </g>
        <g opacity='0.8' filter='url(#filter3_f_99_4842)'>
          <path
            d='M39.4121 -7.60966C48.3022 -0.338909 41.5316 36.2704 38.0937 33.8646C35.9964 32.397 33.5169 19.9821 26.9332 14.6619C22.7245 11.2608 15.1895 13.7425 10.3903 14.0935C-1.9163 14.9935 30.4478 -14.9411 39.4121 -7.60966Z'
            fill='#FF5C00' />
        </g>
      </g>
      <defs>
        <filter id='filter0_dd_99_4842' x='-12' y='-9.2666' width='64' height='64' filterUnits='userSpaceOnUse'
                colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                         result='hardAlpha' />
          <feOffset dy='2' />
          <feGaussianBlur stdDeviation='6' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0.6875 0 0 0 0 0.744318 0 0 0 0 1 0 0 0 0.06 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_99_4842' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                         result='hardAlpha' />
          <feOffset dy='1' />
          <feGaussianBlur stdDeviation='1' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0.549757 0 0 0 0 0.610463 0 0 0 0 0.929167 0 0 0 0.05 0' />
          <feBlend mode='normal' in2='effect1_dropShadow_99_4842' result='effect2_dropShadow_99_4842' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect2_dropShadow_99_4842' result='shape' />
        </filter>
        <filter id='filter1_f_99_4842' x='-15.6451' y='-27.3008' width='70.3076' height='72.2646'
                filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='8' result='effect1_foregroundBlur_99_4842' />
        </filter>
        <filter id='filter2_f_99_4842' x='-21.6921' y='-20.3662' width='62.5917' height='67.2959'
                filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='8' result='effect1_foregroundBlur_99_4842' />
        </filter>
        <filter id='filter3_f_99_4842' x='-8.36633' y='-24.7666' width='68.1775' height='74.7441'
                filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='8' result='effect1_foregroundBlur_99_4842' />
        </filter>
        <clipPath id='clip0_99_4842'>
          <rect y='0.733398' width='40' height='40' rx='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AccountIcon
