import React, { useState } from 'react'
import { css } from 'styled-components'

import { FlexContainer } from '../../../../components/UI/Flex'
import Button from '../../../../components/UI/Button'
import PinnedIcon from '../../../../assets/PinnedIcon'
import Text from '../../../../components/UI/Text'
import { NFTWrapper, RenderCardWrapper, SmallCard } from '../../styled'
import RewardsChart from './Chart'
import { NftType } from '../../../../types/nft'
import { getNumberFromBigInt } from '../../../../utils/formatBigInt'
import { useWriteContract } from 'wagmi'
import { ApyAbi } from '../../../../contracts/Apy'
import { contractsAddresses } from '../../../../contstants/contractsAddresses'
import { getTransactionReceipt } from '../../../../utils/getTransacationReceipt'
import Loader from '../../../../components/UI/Loader'

type ClaimProps = {
  nft: NftType
}

const Claim = ({ nft }: ClaimProps) => {
  const { writeContract } = useWriteContract()

  const [isTxLoading, setIsTxLoading] = useState(false)
  const handleClaimRewards = () => {
    setIsTxLoading(true)
    writeContract(
      {
        abi: ApyAbi,
        address: contractsAddresses.apy,
        functionName: 'claimRewards',
        args: [BigInt(nft.nftId)],
      },
      {
        onSuccess: async data => {
          const transactionReceipt = await getTransactionReceipt(data)
          if (transactionReceipt.status === 'success') {
            setIsTxLoading(false)
          }
        },
        onError: async error => {
          setIsTxLoading(false)
        },
      },
    )
  }
  return (
    <NFTWrapper>
      <FlexContainer width={'100%'} direction={'column'} gap='24px'>
        <RenderCardWrapper bgColor={'green'}>
          <FlexContainer width='100%' direction='column' gap='28px'>
            <FlexContainer
              width={'100%'}
              justify={'space-between'}
              align={'center'}
              gap='16px'
            >
              <PinnedIcon />
              <Text
                fontSize={14}
                fontWeight={500}
                color='#161616'
                customStyle={css`line-height: 20px;`}
              >
                NFT A-91
              </Text>
            </FlexContainer>
            <FlexContainer width={'100%'} direction='column' gap='4px'>
              <Text
                fontSize={40}
                fontWeight={500}
                customStyle={css`line-height: 56px;`}
              >
                {getNumberFromBigInt(nft.amountUSDT)}{' '}
                <Text
                  fontSize={24}
                  fontWeight={500}
                  customStyle={css`line-height: 32px;`}
                >
                  USDT
                </Text>
              </Text>
              <Text
                fontSize={14}
                fontWeight={500}
                color='#16161680'
                customStyle={css`line-height: 20px;`}
              >
                Total balance
              </Text>
            </FlexContainer>
          </FlexContainer>
        </RenderCardWrapper>

        <RenderCardWrapper>
          <FlexContainer width={'100%'} direction='column' gap='12px'>
            <FlexContainer width={'100%'} direction='column' gap='4px'>
              <FlexContainer width={'100%'} justify={'space-between'} align={'flex-start'}>
                <Text
                  fontSize={24}
                  fontWeight={500}
                  customStyle={css`line-height: 40px;`}
                >
                  {getNumberFromBigInt(nft.rewards)}{' '}
                  <Text
                    fontSize={20}
                    fontWeight={500}
                    customStyle={css`line-height: 32px;`}
                  >
                    USDT
                  </Text>
                </Text>
                <SmallCard>
                  <Text
                    fontSize={14}
                    fontWeight={500}
                    color='#474747'
                    customStyle={css`line-height: 20px;`}
                  >
                    0,13% APR
                  </Text>
                </SmallCard>
              </FlexContainer>
              <Text
                fontSize={14}
                fontWeight={500}
                color='#16161680'
                customStyle={css`line-height: 20px;`}
              >
                Rewards balance
              </Text>
            </FlexContainer>

            <RewardsChart />
          </FlexContainer>
        </RenderCardWrapper>

        <FlexContainer width={'100%'} direction={'column'} gap='16px'>
          <Button
            customStyle={css`border-radius: 12px`}
            onClick={handleClaimRewards}
          >
            {isTxLoading ? <Loader /> : 'Claim rewards'}
          </Button>
          <Button onClick={() => {
          }} type='secondary'>
            Actions
          </Button>
        </FlexContainer>
      </FlexContainer>
    </NFTWrapper>
  )
}

export default Claim
