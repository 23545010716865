import React from 'react'
import { Wrapper, CardWrapper, ButtonWrapper } from './styled'
import CirclesDecoration from '../../../../components/CirclesDecoration'
import ArrowUp from '../../../../assets/ArrowUp'
import { FlexContainer } from '../../../../components/UI/Flex'
import Text from '../../../../components/UI/Text'
import { css } from 'styled-components'
import Button from '../../../../components/UI/Button'
import { useNavigate } from 'react-router-dom'

const BuyNFT = () => {
  const navigate = useNavigate()
  const navigateToFinancialNFT = () => {
    navigate('/financial-nft', { replace: true })
  }

  return (
    <Wrapper>
      <CirclesDecoration variant={'main'} />
      <CardWrapper>
        <FlexContainer align={'center'} justify={'flex-start'} gap={'4px'}>
          <ArrowUp />
          <Text fontSize={14} fontWeight={500}>
            +3,51 %
          </Text>
        </FlexContainer>
        <FlexContainer
          direction={'column'}
          align={'flex-start'}
          justify={'flex-start'}
          gap={'16px'}
        >
          <Text fontSize={42} fontWeight={500} customStyle={css`line-height: 56px;`}>
            Unlock Exclusive Investment Opportunities with Our Utility NFTs
          </Text>
          <Text
            fontSize={16}
            fontWeight={500}
            color={'rgba(0, 0, 0, .55)'}
            customStyle={css`line-height: 24px;`}
          >
            Purchase our Utility NFTs and gain access to innovative investment strategies
            with attractive APYs. Benefit from a transparent, low-fee structure and the
            potential for substantial profits.
          </Text>
        </FlexContainer>
        <ButtonWrapper>
          <Button onClick={navigateToFinancialNFT}>Buy NFT</Button>
        </ButtonWrapper>
      </CardWrapper>
    </Wrapper>
  )
}

export default BuyNFT
