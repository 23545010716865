import { createConfig, http } from 'wagmi'
import { bscTestnet,  } from 'viem/chains'
import { walletConnect } from 'wagmi/connectors'

export const config = createConfig({
  chains: [bscTestnet],
  connectors: [
    walletConnect({
      projectId: '0fdebba0250b08c32618bc244f63fe38',
      showQrModal: true,
    }),
  ],
  transports: {
    [bscTestnet.id]: http(),
  },
})
