import React from 'react'
import { FlexContainer } from '../UI/Flex'
import Text from '../UI/Text'
import { ButtonWrapper, Wrapper } from './styled'
import TelegramIcon from './Assets/TelegramIcon'
import EmailIcon from './Assets/EmailIcon'
import { css } from "styled-components";
import { phoneScreen } from "../../types/media";

const Footer = () => {
  return (
    <Wrapper>
      <Text
        fontSize={14}
        fontWeight={500}
        color={'rgba(0, 0, 0, .5)'}
        customStyle={css`
          @media screen and (max-width: ${phoneScreen}) {
            display: none;
          }
        `}
      >
        Copyright © 2024 APYROLL LTD. All rights reserved
      </Text>
      <FlexContainer
        align={'center'}
        justify={'flex-end'}
        gap={'12px'}
        customStyle={css`
          @media screen and (max-width: ${phoneScreen}) {
            width: 100%;
            justify-content: space-between;
          }
        `}
      >
        <ButtonWrapper width={68}>
          <Text fontSize={14} fontWeight={500}>FAQ</Text>
        </ButtonWrapper>
        <ButtonWrapper width={97}>
          <Text fontSize={14} fontWeight={500}>Support</Text>
        </ButtonWrapper>
        <ButtonWrapper width={40}>
          <TelegramIcon />
        </ButtonWrapper>
        <ButtonWrapper width={40}>
          <EmailIcon />
        </ButtonWrapper>
      </FlexContainer>
    </Wrapper>
  )
}

export default Footer
