import styled from 'styled-components'
export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  max-width: 371px;
  height: 508px;
  border-radius: 32px;
  background: #fff;
  padding: 28px;
`
export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding: 2px 8px;
  background: #FFEEE8;
  border-radius: 100px;
`
export const ChartWrapper = styled.div`
  margin-top: -132px;
`
export const ArrowAndChartWrapper = styled.div`
  position: relative;
`
export const ArrowWrapper = styled.div`
  position: absolute;
  left: 130px;
  top: 60px;
  transform-origin: bottom center;
  transform: rotate(0deg);
`
export const BulletsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`
export const Bullet = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.11);
`
export const Dot = styled.div<{ background: string }>`
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background: ${p => p.background};
`
