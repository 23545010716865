import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 32px;
`
export const ButtonWrapper = styled.div<{ width: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 49px;
  background: rgba(255, 255, 255, .44);
  height: 40px;
  width: ${p => p.width}px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(255, 255, 255);
  }
`
