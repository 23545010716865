import React from 'react'

const PinnedIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="32"
        height="32"
        rx="16"
        fill="#FF5500"
        style={{ fill: '#FF5500', fillOpacity: 1 }}
      />
      <path
        d="M15.9999 12L12.6666 10L15.9999 8L19.3333 10L15.9999 12Z"
        fill="white"
        style={{ fill: 'white', fillOpacity: 1 }}
      />
      <path
        d="M22.6666 16V12L19.3333 10V14L22.6666 16Z"
        fill="white"
        style={{ fill: 'white', fillOpacity: 1 }}
      />
      <path
        d="M19.3333 22L22.6666 20V16L19.3333 18V22Z"
        fill="white"
        style={{ fill: 'white', fillOpacity: 1 }}
      />
      <path
        d="M12.6666 22L15.9999 24L19.3333 22L15.9999 20L12.6666 22Z"
        fill="white"
        style={{ fill: 'white', fillOpacity: 1 }}
      />
      <path
        d="M9.33325 16L12.6666 18V22L9.33325 20V16Z"
        fill="white"
        style={{ fill: 'white', fillOpacity: 1 }}
      />
      <path
        d="M9.33325 16L12.6666 14V10L9.33325 12V16Z"
        fill="white"
        style={{ fill: 'white', fillOpacity: 1 }}
      />
    </svg>
  )
}

export default PinnedIcon
