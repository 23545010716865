import styled from 'styled-components'
import { tabletScreen } from '../../../../types/media'

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 184px;
  width: 100%;
  background: #fff;
  border-radius: 20px;
  padding: 20px;

  @media (max-width: ${tabletScreen}) {
    height: 100%;
    gap: 24px;
  }
`
export const Tile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  width: max-content;
  height: 32px;
  background: rgba(0, 155, 54, .2);
  border-radius: 500px;
`
