import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
  min-height: 508px;
  background: #fff;
  border-radius: 24px;
  padding: 30px;
`
export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding: 2px 8px;
  background: #F1F3F5;
  border-radius: 100px;
`

