import styled from 'styled-components'
import { desktopSmScreen } from '../../../../types/media'

export const BuyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 24px;
  background: #fff;
  width: 750px;
  height: 390px;
  padding: 16px;

  @media (max-width: ${desktopSmScreen}) {
    width: 100%;
  }
`
export const APYChart = styled.div`
  width: 100%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  height: 112px;
  border: 1px solid #EEEAFF;
  background: linear-gradient(180deg, rgba(236, 234, 253, 0.52) 0%, rgba(236, 234, 253, 0) 100%);
  overflow: hidden;
`
export const ProfitValueWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding: 2px 8px;
  background: #FFFFFF;
  border-radius: 100px;
`
export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding: 2px 8px;
  background: #EBEAFC;
  border-radius: 100px;
`
