import styled from "styled-components";

export const DotWrapper = styled.div<{isGreen: boolean}>`
  width: 39px;
  height: 19px;
  background-color: ${p => p.isGreen ? '#D5FFE1' : '#FFEEE8'};
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: ${p => p.isGreen ? '#2AC354' : '#FA531C'};
`;
