import React from 'react'
import { FlexContainer } from '../../../../components/UI/Flex'
import { Card, Tile } from './styled'
import Text from '../../../../components/UI/Text'

type InfoCardsProps = {
  amount: string
}

const InfoCards = ({ amount }: InfoCardsProps) => {
  const formatBalance = () => {
    const calculatedAmount = (Number(amount) - (Number(amount) * 0.02))
    return calculatedAmount % 1 === 0 ? calculatedAmount.toFixed(0) : calculatedAmount.toFixed(1)
  }

  return (
    <FlexContainer
      direction={'column'}
      justify={'flex-start'}
      align={'flex-start'}
      gap={'24px'}
      width={'100%'}
    >
      <Card>
        <FlexContainer
          direction={'column'}
          align={'flex-start'}
          justify={'flex-start'}
          gap={'6px'}
        >
          <Text fontSize={14} fontWeight={500} color={'#979BA3'}>
            You get NFT amount
          </Text>
          <Text fontSize={32} fontWeight={500}>
            {formatBalance()}
          </Text>
        </FlexContainer>
        <Tile>
          <Text fontSize={16} fontWeight={500}>
            USDT
          </Text>
        </Tile>
      </Card>
      <FlexContainer
        width={'100%'}
        align={'flex-start'}
        justify={'space-between'}
        gap={'24px'}
      >
        <Card>
          <FlexContainer
            direction={'column'}
            align={'flex-start'}
            justify={'flex-start'}
            gap={'6px'}
          >
            <Text fontSize={14} fontWeight={500} color={'#979BA3'}>
              Lock-Up Period
            </Text>
            <Text fontSize={32} fontWeight={500}>
              1-48
            </Text>
          </FlexContainer>
          <Tile>
            <Text fontSize={16} fontWeight={500}>
              Hours
            </Text>
          </Tile>
        </Card>
        <Card>
          <FlexContainer
            direction={'column'}
            align={'flex-start'}
            justify={'flex-start'}
            gap={'6px'}
          >
            <Text fontSize={14} fontWeight={500} color={'#979BA3'}>
              Platform Fees
            </Text>
            <Text fontSize={32} fontWeight={500}>
              -1.0024{' '}
            </Text>
          </FlexContainer>
          <Tile>
            <Text fontSize={16} fontWeight={500}>
              USDT
            </Text>
          </Tile>
        </Card>
      </FlexContainer>
    </FlexContainer>
  )
}

export default InfoCards
