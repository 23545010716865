import React from 'react'
import { FlexContainer } from '../../components/UI/Flex'
import {
  ConnectWrapper,
  GreenCircleDecoration,
  RedCircleDecoration,
  Wrapper,
} from './styled'
import Text from '../../components/UI/Text'
import Wallets from './components/Wallets'
import { useAccount } from 'wagmi'

const ConnectWallet = () => {
  const { address } = useAccount()
  return (
    <Wrapper>
      <GreenCircleDecoration />
      <RedCircleDecoration />
      <FlexContainer width={'100%'} align={'center'} justify={'center'} padding="40px 0">
        <ConnectWrapper>
          <Text fontSize={24} fontWeight={500}>
            {address ? 'Connected wallet' : 'Connect wallet'}
          </Text>
          <FlexContainer
            width={'100%'}
            direction={'column'}
            align={'flex-start'}
            justify={'flex-start'}
            gap={'24px'}
          >
            <Wallets />
          </FlexContainer>
        </ConnectWrapper>
      </FlexContainer>
    </Wrapper>
  )
}

export default ConnectWallet
