import React, { ReactNode } from 'react'
import { CSSProp } from 'styled-components'
import { StyledButton } from './styled'

interface ButtonProps {
  onClick: () => void
  disabled?: boolean
  customStyle?: CSSProp
  children: string | ReactNode
  type?: 'main' | 'secondary' | 'bordered'
}

const Button = (props: ButtonProps) => {
  const {
    onClick,
    disabled,
    customStyle,
    children,
    type = 'main',
  } = props

  return (
    <StyledButton
      onClick={onClick}
      disabled={disabled}
      customStyle={customStyle}
      type={type}
    >
      {children}
    </StyledButton>
  )
}

export default Button
