import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  min-height: 100vh;
  height: auto;
  width: 100%;
  padding: 24px 0 32px 0;
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  height: calc(100vh - 51px);
  max-width: 1200px;
  padding: 0 32px;
  margin: 0 auto;

  @media (max-width: 1023px) {
    padding: 0 16px;
  }
`
export const MainContent = styled.div`
  display: flex;
  flex: 1;

`
