import styled from 'styled-components'
import { phoneScreen, tabletScreen } from '../../../../types/media'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 1200px;
  height: 632px;
  background: #fff;
  border-radius: 32px;
  padding: 16px;
  overflow: hidden;

  @media (max-width: ${tabletScreen}) { 
    height: auto;
  }
`
export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-width: 468px;
  height: 100%;
  max-height: 600px;
  border-radius: 24px;
  padding: 40px;
  background: #CFFE56;
  z-index: 10;

  @media (max-width: ${tabletScreen}) { 
    max-width: 378px;
    padding: 24px;
    gap: 16px;
  }

  @media (max-width: ${phoneScreen}) { 
    max-width: 100%;
    padding: 20px;
  }
`
export const ButtonWrapper = styled.div`
  max-width: 135px;
  width: 100%;
`
