import React from 'react'
import { BarChart, Bar, ResponsiveContainer, XAxis } from 'recharts'

const data = [
  { name: '1', uv: 200 },
  { name: '2', uv: 250 },
  { name: '3', uv: 220 },
  { name: '4', uv: 280 },
  { name: '5', uv: 230 },
  { name: '6', uv: 260 },
  { name: '7', uv: 270 },
  { name: '8', uv: 240 },
  { name: '9', uv: 300 },
  { name: '10', uv: 220 },
  { name: '11', uv: 260 },
  { name: '12', uv: 280 },
  { name: '13', uv: 300 },
  { name: '14', uv: 250 },
  { name: '15', uv: 270 },
  { name: '16', uv: 260 },
  { name: '17', uv: 250 },
  { name: '18', uv: 240 },
  { name: '19', uv: 270 },
  { name: '20', uv: 280 },
  { name: '21', uv: 290 },
  { name: '22', uv: 300 },
  { name: '23', uv: 320 },
  { name: '24', uv: 310 },
  { name: '25', uv: 330 },
  { name: '26', uv: 340 },
  { name: '27', uv: 350 },
  { name: '28', uv: 360 },
]

const RewardsChart = () => {
  return (
    <ResponsiveContainer width="100%" height={100}>
      <BarChart data={data}>
        <XAxis
          dataKey="name"
          tickLine={false}
          axisLine={false}
          interval={0}
          ticks={['1', '7', '14', '21', '28']}
          tick={{ fontSize: 12, fontWeight: 600 }}
        />
        <Bar dataKey="uv" fill="#40D168" barSize={5} />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default RewardsChart
