import styled, {CSSProp} from 'styled-components';

type FlexContainerProps = {
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  justify?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
  align?: 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline';
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  gap?: string;
  padding?: string;
  margin?: string;
  width?: string;
  height?: string;
  backgroundColor?: string;
  borderRadius?: string;
  border?: string;
  boxShadow?: string;
  customStyle?: CSSProp;
}
type FlexItemProps = {
  flex?: string;
  padding?: string;
  margin?: string;
  width?: string;
  height?: string;
  backgroundColor?: string;
  borderRadius?: string;
  border?: string;
  boxShadow?: string;
  maxScreen?: "1699px" | "1549px" | "1319px" | "1180px" | "1023px" | "767px" | string;
}
export const FlexContainer = styled.div<FlexContainerProps>`
  display: flex;
  flex-direction: ${({ direction = 'row' }) => direction};
  justify-content: ${({ justify = 'flex-start' }) => justify};
  align-items: ${({ align = 'stretch' }) => align};
  flex-wrap: ${({ wrap = 'nowrap' }) => wrap};
  gap: ${({ gap = '0' }) => gap};
  padding: ${({ padding = '0' }) => padding};
  margin: ${({ margin = '0' }) => margin};
  width: ${({ width = 'auto' }) => width};
  height: ${({ height = 'auto' }) => height};
  background-color: ${({ backgroundColor = 'transparent' }) => backgroundColor};
  border-radius: ${({ borderRadius = '0' }) => borderRadius};
  border: ${({ border = 'none' }) => border};
  box-shadow: ${({ boxShadow = 'none' }) => boxShadow};

  ${(props) => props.customStyle}
`
export const FlexItem = styled.div<FlexItemProps>`
  flex: ${({ flex = '0 1 auto' }) => flex};
  padding: ${({ padding = '0' }) => padding};
  margin: ${({ margin = '0' }) => margin};
  width: ${({ width = 'auto' }) => width};
  height: ${({ height = 'auto' }) => height};
  background-color: ${({ backgroundColor = 'transparent' }) => backgroundColor};
  border-radius: ${({ borderRadius = '0' }) => borderRadius};
  border: ${({ border = 'none' }) => border};
  box-shadow: ${({ boxShadow = 'none' }) => boxShadow};
`
