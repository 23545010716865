import React, { useState } from 'react'
import { FlexContainer } from '../../components/UI/Flex'
import Text from '../../components/UI/Text'
import { css } from 'styled-components'
import BuyNFTForm from './components/BuyNFTForm'
import InfoCards from './components/InfoCards'
import { tabletScreen } from '../../types/media'

const FinancialNFT = () => {
  const [amount, setAmount] = useState('')
  return (
    <FlexContainer width={'100%'} padding={'60px 0'} direction={'column'} gap={'30px'}>
      <FlexContainer
        direction={'column'}
        align={'flex-start'}
        justify={'flex-start'}
        gap={'8px'}
      >
        <Text fontSize={40} fontWeight={500}>
          Get Financial NFT
        </Text>
        <Text
          fontSize={16}
          fontWeight={500}
          color={'rgba(0, 0, 0, 0.43)'}
          customStyle={css`line-height: 24px;`}
        >
          Purchase our Utility NFTs and gain access to innovative <br /> investment
          strategies
        </Text>
      </FlexContainer>
      <FlexContainer
        width={'100%'}
        gap={'24px'}
        customStyle={css`
          @media screen and (max-width: ${tabletScreen}) {
            flex-direction: column;
          }
        `}
      >
        <BuyNFTForm setAmount={setAmount} />
        <InfoCards amount={amount} />
      </FlexContainer>
    </FlexContainer>
  )
}

export default FinancialNFT
