import React, { useEffect, useState } from 'react'
import { APYChart, BuyWrapper, ProfitValueWrapper, Title } from './styled'
import { FlexContainer } from '../../../../components/UI/Flex'
import Text from '../../../../components/UI/Text'
import Input from '../../../../components/UI/Input'
import Button from '../../../../components/UI/Button'
import TransactionModal from '../../../../components/Modals/TransactionModal'
import NiceModal from '@ebay/nice-modal-react'
import { TransactionStatus } from '../../../../types/transaction'
import { useNavigate } from 'react-router-dom'
import { css } from 'styled-components'
import { phoneScreen } from '../../../../types/media'
import { useAccount, useReadContract, useWriteContract } from 'wagmi'
import { UsdtAbi } from '../../../../contracts/Usdt'
import { contractsAddresses } from '../../../../contstants/contractsAddresses'
import { DEFAULT_TOKEN_DECIMAL, getNumberFromBigInt } from '../../../../utils/formatBigInt'
import { getTransactionReceipt } from '../../../../utils/getTransacationReceipt'
import { MAX_ALLOWANCE } from '../../../../contstants/allowance'
import Loader from '../../../../components/UI/Loader'
import BigNumber from 'bignumber.js'
import { ApyAbi } from '../../../../contracts/Apy'
import LineChart from '../LineChart'

const minAmount = 100

type BuyNFTFormProps = {
  setAmount: (amount: string) => void
}

const BuyNFTForm = ({ setAmount }: BuyNFTFormProps) => {
  const { writeContract } = useWriteContract()
  const { address } = useAccount()
  const navigate = useNavigate()

  const [usdtAmount, setUsdtAmount] = useState('')
  const [isValueValid, setIsValueValid] = useState(false)
  const [invalidMessage, setInvalidMessage] = useState('')
  const [isTxLoading, setIsTxLoading] = useState(false)

  const { data: usdtBalance, refetch: usdtBalanceRefetch } = useReadContract({
    abi: UsdtAbi,
    address: contractsAddresses.usdt,
    functionName: 'balanceOf',
    args: [address || '0x1'],
  })

  const { data: minAmountUsdt } = useReadContract({
    abi: ApyAbi,
    address: contractsAddresses.apy,
    functionName: 'minAmountUSDT',
    args: [],
  })

  const { data: usdtAllowance, refetch: usdtAllowanceRefetch } =
    useReadContract({
      abi: UsdtAbi,
      address: contractsAddresses.usdt,
      functionName: 'allowance',
      args: [address || '0x1', contractsAddresses.apy],
    })
  const navigateToMain = () => {
    navigate('/', { replace: true })
  }
  const buyToken = async () => {
    if (!isValueValid) return
    setIsTxLoading(true)

    const weiTokensCount = new BigNumber(usdtAmount)
      .times(DEFAULT_TOKEN_DECIMAL)
      .toFixed(0)

    void NiceModal.show(TransactionModal, {
      status: TransactionStatus.PROCESSING,
      amount: usdtAmount,
    })

    writeContract(
      {
        abi: ApyAbi,
        address: contractsAddresses.apy,
        functionName: 'mintNFT',
        args: [BigInt(weiTokensCount)],
      },
      {
        onSuccess: async data => {
          const transactionReceipt = await getTransactionReceipt(data)
          if (transactionReceipt.status === 'success') {
            usdtBalanceRefetch()
            setIsTxLoading(false)
            setUsdtAmount('')
            await NiceModal.show(TransactionModal, {
              status: TransactionStatus.SUCCESS,
              amount: usdtAmount,
            })
          }
        },
        onError: async error => {
          setIsTxLoading(false)
          console.log(error)
          await NiceModal.show(TransactionModal, {
            status: TransactionStatus.ERROR,
            amount: usdtAmount,
          }).then(() => {
            buyToken()
          })
        },
      },
    )
  }
  const approve = () => {
    setIsTxLoading(true)
    writeContract(
      {
        abi: UsdtAbi,
        address: contractsAddresses.usdt,
        functionName: 'approve',
        args: [contractsAddresses.apy, MAX_ALLOWANCE],
      },
      {
        onSuccess: async data => {
          const transactionReceipt = await getTransactionReceipt(data)
          if (transactionReceipt.status === 'success') {
            usdtAllowanceRefetch()
            setIsTxLoading(false)
          }
        },
        onError: error => {
          setIsTxLoading(false)
        },
      },
    )
  }
  const formatBalance = (balance: number) => {
    const parts = balance.toString().split('.')
    const integerPart = parts[0]
    const decimalPart = parts[1] || ''

    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

    if (decimalPart.length === 0) {
      return formattedInteger
    } else if (decimalPart.length === 1 && decimalPart !== '0') {
      return `${formattedInteger}.${decimalPart}`
    } else if (decimalPart.length >= 2 && (decimalPart[0] !== '0' || decimalPart[1] !== '0')) {
      return `${formattedInteger}.${decimalPart.slice(0, 2)}`
    } else {
      return `${formattedInteger}.${decimalPart.slice(0, 6)}`
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (usdtAmount) {
        const amount = parseFloat(usdtAmount)
        if (amount < minAmount) {
          setIsValueValid(false)
          setInvalidMessage(
            `You cannot purchase NFTs for less than the minimum amount of ${minAmount} USDT`,
          )
        } else if (amount > getNumberFromBigInt(usdtBalance)) {
          setIsValueValid(false)
          setInvalidMessage('Insufficient balance')
        } else {
          setIsValueValid(true)
          setInvalidMessage('')
        }
      } else {
        setIsValueValid(false)
        setInvalidMessage('')
      }
    }, 1000)

    return () => clearTimeout(timer)
  }, [usdtAmount])

  const isApprove = getNumberFromBigInt(usdtAllowance) <= 0

  return (
    <FlexContainer
      width={'100%'}
      align={'flex-start'}
      justify={'space-between'}
      gap={'24px'}
    >
      <BuyWrapper>
        <APYChart>
          <FlexContainer justify='space-between' padding='15px 15px 0'>
            <FlexContainer align={'flex-start'} justify={'flex-start'} gap={'8px'}>
              <Text fontSize={32} fontWeight={500}>
                24,5%
              </Text>
              <ProfitValueWrapper>
                <Text fontSize={14} fontWeight={500} color={'#474747'}>
                  +3.4%
                </Text>
              </ProfitValueWrapper>
            </FlexContainer>
            <Title>
              <Text fontSize={12} fontWeight={600} color={'#6557FA'}>
                AVERAGE APY CHART
              </Text>
            </Title>
          </FlexContainer>

          <LineChart />
        </APYChart>
        <FlexContainer
          width={'461px'}
          direction={'column'}
          align={'flex-start'}
          justify={'flex-start'}
          gap={'32px'}
          customStyle={css`
            @media (max-width: ${phoneScreen}) {
              max-width: 461px;
              width: 100%;
            }
          `}
        >
          <FlexContainer
            width={'100%'}
            direction={'column'}
            align={'flex-start'}
            justify={'flex-start'}
            gap={'8px'}
          >
            <FlexContainer width={'100%'} align={'center'} justify={'space-between'}>
              <Text fontSize={16} fontWeight={500}>
                Amount
              </Text>
              <Text fontSize={16} fontWeight={500} color={invalidMessage ? 'red' : '#889096'}>
                Balance: {formatBalance(getNumberFromBigInt(usdtBalance))} USDT
              </Text>
            </FlexContainer>
            <Input
              onChange={e => {
                setAmount(e)
                setUsdtAmount(e)
              }}
              type={'number'}
              value={usdtAmount}
              placeholder={''}
              isValueValid={isValueValid}
              invalidMessage={invalidMessage}
              subtitle={'Min amount 100 USDT'}
            />
          </FlexContainer>
          <FlexContainer
            width={'100%'}
            align={'center'}
            justify={'space-between'}
            gap={'20px'}
          >
            <Button onClick={navigateToMain} type={'secondary'}>
              Back
            </Button>
            <Button
              onClick={isApprove ? approve : buyToken}
              disabled={
                !(isValueValid && address) ||
                isTxLoading
              }
              customStyle={css`
                border-radius: 12px;
                font-size: 16px;
              `}
            >
              {isTxLoading ? (
                <Loader />
              ) : 'Create & Buy'}
            </Button>
          </FlexContainer>
        </FlexContainer>
      </BuyWrapper>
    </FlexContainer>
  )
}

export default BuyNFTForm
