import React, { useState } from 'react'
import { FlexContainer } from '../../components/UI/Flex'
import { Wrapper, TextsWrapper } from './styled'
import FirstScreenIcons from './components/FirstScreenIcon'
import Text from '../../components/UI/Text'
import { css } from 'styled-components'
import Button from '../../components/UI/Button'
import CirclesDecoration from '../../components/CirclesDecoration'
import { useNavigate } from 'react-router-dom'
import { phoneScreen } from '../../types/media'

enum OnboardingSteps {
  FIRST_SCREEN = 'FIRST_SCREEN',
  SECOND_SCREEN = 'SECOND_SCREEN',
}

const screensData = {
  [OnboardingSteps.FIRST_SCREEN]: {
    title: 'Unlock Exclusive Investment Opportunities with Our Utility NFTs',
    subtitle:
      'Purchase our Utility NFTs and gain access to innovative investment strategies with attractive APYs. Benefit from a transparent, low-fee structure and the potential for substantial profits.',
    image: <FirstScreenIcons />,
  },
  [OnboardingSteps.SECOND_SCREEN]: {
    title: 'Benefit from a transparent, low-fee structure',
    subtitle:
      'Purchase our Utility NFTs and gain access to innovative investment strategies with attractive APYs. Benefit from a transparent, low-fee structure and the potential for substantial profits.',
    image: <CirclesDecoration variant={'onboarding'} />,
  },
}

const Onboarding = () => {
  const navigate = useNavigate()

  const [activeScreen, setActiveScreen] = useState(OnboardingSteps.FIRST_SCREEN)
  const handleNext = () => {
    if (activeScreen === OnboardingSteps.SECOND_SCREEN) {
      navigate('/')
      return
    }
    setActiveScreen(OnboardingSteps.SECOND_SCREEN)
  }
  const handleBack = () => {
    if (activeScreen === OnboardingSteps.FIRST_SCREEN) {
      navigate('/')
      return
    }
    setActiveScreen(OnboardingSteps.FIRST_SCREEN)
  }

  const { title, subtitle, image } = screensData[activeScreen]

  return (
    <FlexContainer
      width={'100%'}
      align={'center'}
      justify={'center'}
      padding="32px 0"
    >
      <Wrapper>
        {image}
        <TextsWrapper>
          <FlexContainer
            direction={'column'}
            align={'flex-start'}
            justify={'space-between'}
            gap={'40px'}
          >
            <FlexContainer
              direction={'column'}
              align={'flex-start'}
              justify={'flex-start'}
              gap={'16px'}
            >
              <Text
                fontSize={40}
                fontWeight={500}
                customStyle={css`
                  text-align: left;
                  line-height: 56px;
                  @media screen and (max-width: ${phoneScreen}) {
                    line-height: normal;
                  }
                `}
              >
                {title}
              </Text>
              <Text
                fontSize={16}
                fontWeight={500}
                color={'rgba(0, 0, 0, .56)'}
                customStyle={css`text-align: left;`}
              >
                {subtitle}
              </Text>
            </FlexContainer>
            <FlexContainer
              align={'center'}
              justify={'space-between'}
              gap={'20px'}
              width={'100%'}
            >
              <Button onClick={handleBack} type={'secondary'}>
                {activeScreen === OnboardingSteps.FIRST_SCREEN ? 'Skip' : 'Back'}
              </Button>
              <Button onClick={handleNext}>
                {activeScreen === OnboardingSteps.FIRST_SCREEN ? 'Next' : 'Start'}
              </Button>
            </FlexContainer>
          </FlexContainer>
        </TextsWrapper>
      </Wrapper>
    </FlexContainer>
  )
}

export default Onboarding
