import React, { useState } from 'react'
import { TableWrapper, TDStyled, THStyled, Title, TRStyled, Wrapper, TableContainer } from './styled'
import Text from '../../../../components/UI/Text'
import { FlexContainer } from '../../../../components/UI/Flex'
import Button from '../../../../components/UI/Button'

const TableTransactions = () => {
  const dataTD = [
    {
      name: 'Buy NFT',
      date: '12 April 2024',
      amount: '-249 USDT',
    },
    {
      name: 'Gas fee',
      date: '16 March 2024',
      amount: '-0.0259 USDT',
    },
    {
      name: 'Withdrew rewards',
      date: '02 February 2024',
      amount: '+249 USDT',
    },
    {
      name: 'Buy NFT',
      date: '15 January 2024',
      amount: '-1,000.00 USDT',
    },
    {
      name: 'Sell NFT',
      date: '10 January 2024',
      amount: '+1,500.00 USDT',
    },
    {
      name: 'Stake rewards',
      date: '01 January 2024',
      amount: '+500 USDT',
    },
    {
      name: 'Buy Token',
      date: '25 December 2023',
      amount: '-100 USDT',
    },
    {
      name: 'Gas fee',
      date: '24 December 2023',
      amount: '-0.030 USDT',
    },
    {
      name: 'Received Rewards',
      date: '20 December 2023',
      amount: '+200 USDT',
    },
    {
      name: 'Sold Token',
      date: '15 December 2023',
      amount: '+50 USDT',
    },
  ]

  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 4

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = dataTD.slice(indexOfFirstItem, indexOfLastItem)

  const handleNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1)
  }

  const handlePreviousPage = () => {
    setCurrentPage(prevPage => prevPage - 1)
  }

  return (
    <Wrapper>
      <FlexContainer width="100%" direction={'column'} gap={'33px'}>
        <Title>
          <Text fontSize={12} fontWeight={600} color={'#000000'}>
            Transactions history
          </Text>
        </Title>
        <TableContainer>
          <TableWrapper>
            <TRStyled>
              <THStyled>Name</THStyled>
              <THStyled>Date</THStyled>
              <THStyled>Amount</THStyled>
            </TRStyled>
            {currentItems.map((el, index) => (
              <TRStyled key={index}>
                <TDStyled>{el.name}</TDStyled>
                <TDStyled>{el.date}</TDStyled>
                <TDStyled>{el.amount}</TDStyled>
              </TRStyled>
            ))}
          </TableWrapper>
        </TableContainer>
        <FlexContainer
          width="100%"
          justify="space-between"
          align="center"
          padding="0 24px"
        >
          <Text fontSize={14} fontWeight={400} color="#687076">
            Page {currentPage} of {Math.ceil(dataTD.length / itemsPerPage)}
          </Text>
          <FlexContainer align="center" gap="20px">
            <Button
              onClick={handlePreviousPage}
              type={'secondary'}
              customStyle={{
                minWidth: '100px',
                width: '100%',
                height: '40px',
              }}
              disabled={currentPage === 1}
            >
              Back
            </Button>
            <Button
              onClick={handleNextPage}
              type={'main'}
              customStyle={{
                minWidth: '100px',
                width: '100%',
                height: '40px',
              }}
              disabled={currentPage === Math.ceil(dataTD.length / itemsPerPage)}
            >
              Next
            </Button>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </Wrapper>
  )
}

export default TableTransactions
