import React from 'react'

import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  AreaChart,
  Area,
} from 'recharts'

type TokenPriceChart<T> = {
  data: T[]
}
const TokenPriceChart = <T,>({ data }: TokenPriceChart<T>) => {
  const formatYAxis = (tickItem: number) => {
    return `${tickItem / 1000}k`
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart data={data}>
        <defs>
          <linearGradient
            id="gradient"
            x1="670.155"
            y1="224.757"
            x2="670.155"
            y2="-0.33004"
            gradientUnits="userSpaceOnUse"
          >
            <stop
              stopColor="#40D168"
              stopOpacity="0"
              style={{ stopColor: 'none', stopOpacity: 0 }}
            />
            <stop
              offset="1"
              stopColor="#40D168"
              stopOpacity="0.6"
              style={{
                stopColor: '#40D168',
                stopOpacity: 0.6,
              }}
            />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="1 1" />
        <XAxis dataKey="name" />
        <YAxis tickFormatter={formatYAxis} />
        <Area
          type="monotone"
          dataKey="uv"
          stroke="#40D168"
          strokeWidth={4}
          fillOpacity={1}
          fill="url(#gradient)"
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default TokenPriceChart
