import React from 'react'

const TelegramIcon = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='20' height='20' rx='10' fill='#282828'
             />
      <path fillRule='evenodd' clipRule='evenodd'
            d='M4.5266 9.89397C7.4418 8.62386 9.38573 7.78653 10.3584 7.38197C13.1355 6.22688 13.7125 6.02623 14.0887 6.0196C14.1714 6.01814 14.3564 6.03865 14.4762 6.13587C14.5773 6.21796 14.6052 6.32885 14.6185 6.40668C14.6318 6.48451 14.6484 6.66181 14.6352 6.80034C14.4847 8.38158 13.8335 12.2188 13.5023 13.9898C13.3621 14.7392 13.0861 14.9905 12.8188 15.0151C12.2381 15.0685 11.7972 14.6313 11.2347 14.2626C10.3546 13.6857 9.85735 13.3265 9.00303 12.7635C8.01572 12.1129 8.65575 11.7553 9.21842 11.1709C9.36567 11.018 11.9243 8.69066 11.9739 8.47954C11.9801 8.45313 11.9858 8.35471 11.9273 8.30274C11.8689 8.25077 11.7826 8.26854 11.7203 8.28267C11.632 8.30271 10.226 9.23203 7.50222 11.0706C7.10313 11.3447 6.74164 11.4782 6.41776 11.4712C6.06071 11.4635 5.37389 11.2693 4.8633 11.1034C4.23705 10.8998 3.73932 10.7922 3.78266 10.4465C3.80524 10.2664 4.05321 10.0822 4.5266 9.89397Z'
            fill='white' />
    </svg>
  )
}

export default TelegramIcon
