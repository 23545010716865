import React from 'react'

const Arrow = () => {
  return (
    <svg width='29' height='30' viewBox='0 0 29 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd'
            d='M11.8723 10.2174C11.4015 10.6881 11.4015 11.4514 11.8723 11.9222L15.2392 15.2892L11.8723 18.6561C11.4015 19.1269 11.4015 19.8902 11.8723 20.361C12.3431 20.8318 13.1064 20.8318 13.5772 20.361L17.7966 16.1416C18.2674 15.6708 18.2674 14.9075 17.7966 14.4367L13.5772 10.2174C13.1064 9.74656 12.3431 9.74656 11.8723 10.2174Z'
            fill='#23262F' />
    </svg>
  )
}

export default Arrow
