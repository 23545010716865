import React from 'react'
import GenericModal from '../GenericModal'
import NiceModal, { NiceModalHandler, useModal } from '@ebay/nice-modal-react'
import Text from '../../UI/Text'
import Button from '../../UI/Button'
import { FlexContainer } from '../../UI/Flex'
import { Wrapper, ContentWrapper } from './styled'
import TransactionIcon from '../../../assets/TransactionIcon'
import TransactionErrorIcon from '../../../assets/static/TransactionErrorIcon'
import { css } from 'styled-components'
import { TransactionStatus } from '../../../types/transaction'

const getContentByStatus = (status: TransactionStatus, modal: NiceModalHandler) => {
  switch (status) {
    case TransactionStatus.ERROR:
      return {
        title: 'Error...',
        icon: <TransactionErrorIcon />,
        text: 'Minting error',
        buttons: (
          <FlexContainer width={'100%'} direction={'column'} gap={'16px'}>
            <Button
              onClick={() => modal.resolve({ repeat: true })}
              customStyle={css`border-radius: 12px`}
            >
              Try again
            </Button>
            <Button onClick={() => modal.remove()} type={'secondary'}>
              Back
            </Button>
          </FlexContainer>
        ),
      }
    case TransactionStatus.SUCCESS:
      return {
        title: 'Successfully purchased...',
        icon: <TransactionIcon />,
        text: 'Minted NFT',
        buttons: (
          <FlexContainer width={'100%'} direction={'column'} gap={'16px'}>
            <Button onClick={() => modal.remove()} customStyle={css`border-radius: 12px`}>
              Done
            </Button>
            <Button onClick={() => modal.remove()} type={'secondary'}>
              Get more NFT
            </Button>
          </FlexContainer>
        ),
      }
    default:
      return {
        title: 'Processing...',
        icon: <TransactionIcon />,
        text: 'Minting NFT',
        buttons: (
          <Button onClick={() => null} disabled customStyle={css`border-radius: 12px`}>
            Waiting for confirm...
          </Button>
        ),
      }
  }
}

type TransactionModalProps = {
  status: TransactionStatus
  amount: string
}

const TransactionModal = NiceModal.create(({ status, amount }: TransactionModalProps) => {
  const modal = useModal()
  const content = getContentByStatus(status, modal)

  return (
    <GenericModal>
      <Wrapper>
        <Text fontSize={24} fontWeight={500}>
          {content.title}
        </Text>
        <ContentWrapper isGreenBg={status === TransactionStatus.SUCCESS}>
          <FlexContainer width={'100%'} align={'center'} justify={'space-between'}>
            {content.icon}
            <Text fontSize={14} fontWeight={500}>
              NFT A-91
            </Text>
          </FlexContainer>
          <FlexContainer
            direction={'column'}
            align={'flex-start'}
            justify={'flex-end'}
            gap={'12px'}
          >
            <FlexContainer align={'flex-end'} gap={'8px'}>
              <Text fontSize={40} fontWeight={500} customStyle={css`height: 40px;`}>
                +{amount}
              </Text>
              <Text fontSize={24} fontWeight={500}>
                USDT
              </Text>
            </FlexContainer>
            <Text fontSize={14} fontWeight={500} color={'rgba(0, 0, 0, 0.5)'}>
              {content.text}
            </Text>
          </FlexContainer>
        </ContentWrapper>
        {content.buttons}
      </Wrapper>
    </GenericModal>
  )
})
export default TransactionModal
