import React from 'react'

const ArrowUp = () => {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='32' height='32' rx='16' fill='#161616' />
      <path
        d='M14.87 22.6V13.072L11.078 17.272L9.54203 15.928L15.95 9.328H16.046L22.43 15.928L20.918 17.272L17.102 13.072V22.6H14.87Z'
        fill='#CFFE56' />
    </svg>
  )
}

export default ArrowUp
