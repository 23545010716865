import styled from 'styled-components'
export const ModalOverlay = styled.div`
  position: fixed;
  z-index: 5;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(50px);
`
export const Wrapper = styled.div`
  border-radius: 32px;
  background: #fff;
  padding: 30px 16px;
`
