import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { WagmiProvider } from 'wagmi'
import { config } from './wagmi/config'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Layout from './layout'
import { BrowserRouter } from 'react-router-dom'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 0,
      staleTime: 0,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    },
    mutations: {
      gcTime: 0,
    },
  },
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
)

root.render(
  <React.StrictMode>
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Layout>
            <App />
          </Layout>
        </BrowserRouter>
      </QueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>,
)
