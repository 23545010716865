import React from 'react'

const RiskAndProfitIndexArrow = () => {
  return (
    <svg width='63' height='129' viewBox='0 0 63 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M33.7596 2.95041e-05L40.6488 106.747C40.9116 110.818 37.6773 114.263 33.5978 114.257C29.5184 114.251 26.2939 110.798 26.5681 106.727L33.7596 2.95041e-05Z'
        fill='#323232'  />
      <circle cx='34.2077' cy='113.669' r='12.2077' fill='#323232' stroke='white' strokeWidth='6' />
    </svg>
  )
}

export default RiskAndProfitIndexArrow
