import React from 'react'
import { FlexContainer } from '../UI/Flex'
import { Circle, Wrapper } from './styled'

type CirclesDecorationProps = {
  variant: 'onboarding' | 'main'
}

enum CircleColor {
  Red = '#FA531C',
  Grey = '#EEF1F6',
}

const onboardingVariant: CircleColor[][] = [
  [CircleColor.Red, CircleColor.Red, CircleColor.Grey, CircleColor.Grey, CircleColor.Grey, CircleColor.Grey],
  [CircleColor.Red, CircleColor.Red, CircleColor.Red, CircleColor.Red, CircleColor.Red, CircleColor.Grey],
  [CircleColor.Red, CircleColor.Red, CircleColor.Red, CircleColor.Red, CircleColor.Red, CircleColor.Red],
  [CircleColor.Red, CircleColor.Red, CircleColor.Red, CircleColor.Grey, CircleColor.Grey, CircleColor.Grey],
]

const mainVariant: CircleColor[][] = [
  [CircleColor.Red, CircleColor.Red, CircleColor.Grey, CircleColor.Grey, CircleColor.Grey, CircleColor.Grey, CircleColor.Grey, CircleColor.Grey, CircleColor.Grey, CircleColor.Grey],
  [CircleColor.Red, CircleColor.Red, CircleColor.Red, CircleColor.Red, CircleColor.Red, CircleColor.Grey, CircleColor.Grey, CircleColor.Grey, CircleColor.Grey, CircleColor.Grey],
  [CircleColor.Red, CircleColor.Red, CircleColor.Red, CircleColor.Red, CircleColor.Red, CircleColor.Red, CircleColor.Red, CircleColor.Red, CircleColor.Red, CircleColor.Red],
  [CircleColor.Red, CircleColor.Red, CircleColor.Red, CircleColor.Grey, CircleColor.Grey, CircleColor.Grey, CircleColor.Grey, CircleColor.Grey, CircleColor.Grey, CircleColor.Grey],
  [CircleColor.Red, CircleColor.Red, CircleColor.Red, CircleColor.Red, CircleColor.Grey, CircleColor.Grey, CircleColor.Grey, CircleColor.Grey, CircleColor.Grey, CircleColor.Grey],
]

const CirclesDecoration = ({ variant }: CirclesDecorationProps) => {
  const activeCircles = variant === 'onboarding' ? onboardingVariant : mainVariant
  return (
    <Wrapper variant={variant}>
      {activeCircles.map((row, index) => (
        <FlexContainer key={index} width={'100%'} align={'center'} justify={'space-between'} gap={'20px'}>
          {row.map((background, circleIndex) => (
            <Circle key={circleIndex} background={background} />
          ))}
        </FlexContainer>
      ))}
    </Wrapper>
  )
}

export default CirclesDecoration
