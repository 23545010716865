import styled from 'styled-components'

export const NFTWrapper = styled.div`
  max-width: 393px;
  min-width: 343px;
  width: 100%;
  height: 580px;
  padding: 28px;

  border-radius: 32px;
  background: #ffffff;
`

type BgColor = 'gray' | 'green' | 'orange' | 'white'

const backgroundColors = {
  gray: '#F1F3F5',
  green: '#CFFE56',
  orange: '#FFE8D7',
  white: '#ffffff',
}

interface CardWrapperProps {
  bgColor?: BgColor
}

export const RenderCardWrapper = styled.div<CardWrapperProps>`
  width: 100%;
  height: 100%;
  padding: 16px;

  display: flex;
  flex: 1;
  flex-direction: column;

  border-radius: 20px;
  background-color: ${({ bgColor }) => backgroundColors[bgColor || 'gray']};
`

export const SmallCard = styled.div`
  width: max-content;
  padding: 2px 8px 2px 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  border-radius: 10px;
  background-color: #ffffff;
`

export const BuyMoreNFT = styled.div`
  position: relative;
  
  width: 100%;
  height: 100%;
  padding: 16px;

  border-radius: 32px;
  background-color: #fff;
  overflow: hidden;

  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("/assets/dashboard-card-bg.svg") no-repeat;
    background-size: cover;
    z-index: 0;
    pointer-events: none;
  }
`

export const CardWrapper = styled.div`
  position: relative;

  width: 100%;
  height: auto;
  padding: 28px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  border-radius: 32px;
  background-color: #F1F3F5;
  z-index: 10;
`
