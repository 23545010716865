import React from 'react'
import { WalletIcon, WalletItem } from '../../styled'
import { FlexContainer } from '../../../../components/UI/Flex'
import Text from '../../../../components/UI/Text'
import Arrow from '../../assets/Arrow'
import { Connector, CreateConnectorFn, useAccount, useConnect, useDisconnect } from 'wagmi'
import WalletConnect from '../../assets/WalletConnect.png'
import Metamask from '../../assets/Metamask.png'
import AccountIcon from '../../assets/AccountIcon'
import Button from '../../../../components/UI/Button'
import { useNavigate } from 'react-router-dom'
import { shortenWalletAddress } from '../../../../utils/wallet'

const Wallets = () => {
  const { connect, connectors } = useConnect()
  const { address } = useAccount()
  const { disconnect } = useDisconnect()
  const navigate = useNavigate()
  const connectWallet = (connector: CreateConnectorFn | Connector) => {
    connect({ connector })
  }
  const goToMainScreen = () => {
    navigate('/', { replace: true })
  }

  const filteredConnectorsArray = connectors
    .filter(connector => connector.id === 'walletConnect' || connector.id === 'io.metamask')
    .map(connector => ({
      name: connector.id === 'walletConnect' ? 'Wallet Connect' : 'Metamask',
      id: connector.id,
      connector: connector,
      LogoComponent: <WalletIcon src={connector.id === 'walletConnect' ? WalletConnect : Metamask} />,
    }))

  return (
    address ?
      <FlexContainer
        width={'100%'}
        direction={'column'}
        align={'flex-start'}
        justify={'flex-start'}
        gap={'16px'}
      >
        <WalletItem>
          <FlexContainer align={'center'} justify={'flex-start'} gap={'14px'}>
            <AccountIcon />
            <Text fontSize={18} fontWeight={500}>{shortenWalletAddress(address)}</Text>
          </FlexContainer>
        </WalletItem>
        <FlexContainer
          width={'100%'}
          direction={'column'}
          align={'flex-start'}
          justify={'flex-start'}
          gap={'16px'}
        >
          <Button onClick={disconnect}>Disconnect</Button>
          <Button onClick={goToMainScreen} type={'secondary'}>Cancel</Button>
        </FlexContainer>
      </FlexContainer>
      :
      filteredConnectorsArray.map(connector => (
        <WalletItem
          key={connector.id}
          onClick={() => connectWallet(connector.connector)}
        >
          <FlexContainer align={'center'} justify={'flex-start'} gap={'14px'}>
            {connector.LogoComponent}
            <Text fontSize={18} fontWeight={600}>{connector.name}</Text>
          </FlexContainer>
          <Arrow />
        </WalletItem>
      ))
  )
}

export default Wallets


