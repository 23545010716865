import { ResponsiveContainer, AreaChart, Area } from 'recharts'

const data = [
  { name: 'Page A', uv: 1890 },
  { name: 'Page B', uv: 3000 },
  { name: 'Page C', uv: 2000 },
  { name: 'Page D', uv: 2780 },
  { name: 'Page E', uv: 4000 },
  { name: 'Page F', uv: 2390 },
  { name: 'Page G', uv: 3490 },
]

const LineChart = () => (
  <ResponsiveContainer width="100%" height={'100%'}>
    <AreaChart
      height={74}
      data={data}
      margin={{
        top: 10,
        right: 0,
        left: 0,
        bottom: 0,
      }}
    >
      <defs>
        <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor="#978FED" />
          <stop offset="100%" stopColor="#D4D2FF" stopOpacity={0} />
        </linearGradient>
      </defs>
      <Area
        type="monotone"
        dataKey="uv"
        stroke="#978FED"
        strokeWidth={3}
        fill="url(#gradient)"
      />
    </AreaChart>
  </ResponsiveContainer>
)

export default LineChart
