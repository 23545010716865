import React from 'react'
import { ButtonWrapper, LogoImg, Wrapper } from './styled'
import { FlexContainer } from '../UI/Flex'
import Logo from '../../assets/static/Logo.png'
import Text from '../UI/Text'
import { css } from 'styled-components'
import Button from '../UI/Button'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import { useAccount } from 'wagmi'
import WalletAvatar from './assets/WalletAvatar'
import { phoneScreen, smallPhoneScreen } from '../../types/media'

const Header = () => {
  const { address } = useAccount()
  const navigate = useNavigate()
  const location = useLocation()

  const navigateToConnectWallet = () => {
    navigate('/connect', { replace: true })
  }
  const navigateToAbout = () => {
    navigate('/about', { replace: true })
  }
  const navigateToBuyNFT = () => {
    navigate('/financial-nft', { replace: true })
  }
  const navigateToDashboard = () => {
    navigate('/dashboard', { replace: true })
  }

  return (
    <Wrapper>
      <FlexContainer
        align={'center'}
        justify={'flex-start'}
        gap={'55px'}
        customStyle={css`
          @media screen and (max-width: ${phoneScreen}) {
            gap: 20px;
          }
          @media screen and (max-width: ${smallPhoneScreen}) {
            gap: 8px;
          }
        `}
      >
        <NavLink to={'/dashboard'} >
          <LogoImg src={Logo} />
        </NavLink>

        <FlexContainer
          justify={'flex-start'}
          align={'center'}
          gap={'24px'}
          customStyle={css`
            @media screen and (max-width: ${phoneScreen}) {
              gap: 12px;
            }
            @media screen and (max-width: ${smallPhoneScreen}) {
              gap: 8px;
            }
          `}
        >
          <Text
            fontSize={14}
            fontWeight={500}
            customStyle={css`
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              width: 120px;
              height: 40px;
              border-radius: 40px;
              transition: all 0.3s ease;
              background-color: ${location.pathname === '/dashboard' ? '#F3F3F3' : 'transparent'};

              &:hover {
                background-color: #F3F3F3;
              }

              @media screen and (max-width: ${smallPhoneScreen}) {
                width: 100px;
                height: 36px;
                font-size: 12px;
              }
            `}
            onClick={navigateToDashboard}
          >
            Dashboard
          </Text>
          <Text
            fontSize={14}
            fontWeight={500}
            customStyle={css`
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              width: 120px;
              height: 40px;
              border-radius: 40px;
              transition: all 0.3s ease;
              background-color: ${location.pathname === '/about' ? '#F3F3F3' : 'transparent'};

              &:hover {
                background-color: #F3F3F3;
              }

              @media screen and (max-width: ${smallPhoneScreen}) {
                width: 100px;
                height: 36px;
                font-size: 12px;
              }
            `}
            onClick={navigateToAbout}
          >
            About
          </Text>
        </FlexContainer>
      </FlexContainer>
      <FlexContainer width={'100%'} align={'center'} justify={'flex-end'} gap={'12px'}>
        {address && (
          <ButtonWrapper>
            <Button
              onClick={navigateToBuyNFT}
              customStyle={css`
                @media screen and (max-width: ${phoneScreen}) {
                  width: 100px;
                }
                @media screen and (max-width: ${smallPhoneScreen}) {
                  width: 80px;
                  font-size: 12px;
                }
              `}
            >
              Buy NFT
            </Button>
          </ButtonWrapper>
        )}
        <ButtonWrapper>
          <Button
            onClick={navigateToConnectWallet}
            type={address ? 'bordered' : 'main'}
            customStyle={css`
              @media screen and (max-width: ${phoneScreen}) {
                border: none;
              }
              @media screen and (max-width: ${smallPhoneScreen}) {
                font-size: 12px;
              }
            `}
          >
            {address ? (
              <FlexContainer align={'center'} justify={'flex-start'} gap={'4px'}>
                <WalletAvatar />
                <Text
                  fontSize={14}
                  fontWeight={500}
                  customStyle={css`
                    @media screen and (max-width: ${phoneScreen}) {
                      display: none;
                    }
                    @media screen and (max-width: ${smallPhoneScreen}) {
                      font-size: 12px;
                    }
                  `}
                >
                  {address.slice(0, 4)}...{address.slice(address.length - 2, address.length)}
                </Text>
              </FlexContainer>
            ) : (
              'Connect wallet'
            )}
          </Button>
        </ButtonWrapper>
      </FlexContainer>
    </Wrapper>
  )
}

export default Header
