import React from 'react'
import { CustomText } from './styled'
import { CSSProp } from 'styled-components'
interface TextProps {
  color?: string
  fontSize: number
  fontWeight: number
  children: React.ReactNode | React.ReactNode[]
  onClick?: () => void
  customStyle?: CSSProp
}

const Text = (props: TextProps) => {
  const { color = '#000', fontSize, fontWeight, children, onClick, customStyle } = props

  return (
    <CustomText
      onClick={onClick}
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
      css={customStyle}
    >
      {children}
    </CustomText>
  )
}

export default Text
