import React from 'react'

const WalletAvatar = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_45_800)">
        <g filter="url(#filter0_dd_45_800)">
          <circle cx="9" cy="9" r="9" fill="#F8F8F8" />
        </g>
        <g opacity="0.8" filter="url(#filter1_f_45_800)">
          <ellipse cx="8.89486" cy="8.7844" rx="8.89486" ry="8.7844" transform="matrix(-0.288205 -0.957569 0.936518 -0.35062 3.11566 15.2412)" fill="#FFAE73" />
        </g>
        <g opacity="0.8" filter="url(#filter2_f_45_800)">
          <ellipse cx="7.54767" cy="7.31111" rx="7.54767" ry="7.31111" transform="matrix(-0.482888 -0.875682 0.798496 -0.602 2.12842 16.6572)" fill="#FF00E5" />
        </g>
        <g opacity="0.8" filter="url(#filter3_f_45_800)">
          <path d="M17.7354 -3.75398C21.736 -0.482147 18.6892 15.992 17.1422 14.9094C16.1984 14.249 15.0826 8.66233 12.12 6.2682C10.226 4.73772 6.83526 5.85449 4.67562 6.01243C-0.862335 6.41743 13.7015 -7.05312 17.7354 -3.75398Z" fill="#FF0000" />
        </g>
      </g>
      <defs>
        <filter id="filter0_dd_45_800" x="-12" y="-10" width="42" height="42" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="2"/>
          <feGaussianBlur stdDeviation="6"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.6875 0 0 0 0 0.744318 0 0 0 0 1 0 0 0 0.06 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_45_800"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="1"/>
          <feGaussianBlur stdDeviation="1"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.549757 0 0 0 0 0.610463 0 0 0 0 0.929167 0 0 0 0.05 0"/>
          <feBlend mode="normal" in2="effect1_dropShadow_45_800" result="effect2_dropShadow_45_800"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_45_800" result="shape"/>
        </filter>
        <filter id="filter1_f_45_800" x="-3.84033" y="-9.41602" width="25.2384" height="26.1191" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_45_800"/>
        </filter>
        <filter id="filter2_f_45_800" x="-6.56152" y="-6.29492" width="21.7663" height="23.8828" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_45_800"/>
        </filter>
        <filter id="filter3_f_45_800" x="-0.564819" y="-8.27441" width="24.2798" height="27.2344" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_45_800"/>
        </filter>
        <clipPath id="clip0_45_800">
          <rect width="18" height="18" rx="9" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default WalletAvatar
