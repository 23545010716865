import React from 'react'

const HeartIcon = () => {
  return (
    <svg width='295' height='295' viewBox='0 0 295 295' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path opacity='0.2'
            d='M87.8192 235.285C136.32 268.227 202.343 255.613 235.285 207.112C268.227 158.611 255.613 92.5885 207.112 59.6466C158.611 26.7047 92.5885 39.318 59.6466 87.8191C26.7047 136.32 39.318 202.343 87.8192 235.285Z'
            fill='#FF435A' />
      <path
        d='M151.354 90.9474C160.62 97.5637 166.69 108.92 165.628 120.362C181.943 112.176 203.895 120.838 211.314 137.329C218.653 152.966 213.145 173.196 199.096 183.043C190.594 189.076 180.511 192.427 170.395 194.698C150.838 198.751 130.818 200.177 110.868 200.973C107.718 191.802 104.443 182.659 101.935 173.286C98.0894 159.901 94.4293 146.326 93.5368 132.393C93.0021 123.099 93.6213 113.401 97.751 104.923C102.868 94.8128 112.694 87.1656 123.855 84.8537C133.32 82.8449 143.574 85.1295 151.354 90.9474Z'
        fill='#FF435A' />
      <path
        d='M111.326 129.952L126.581 140.313L124.375 151.84C124.311 152.176 124.701 152.409 124.966 152.193L140.646 139.399C140.922 139.174 141.325 139.436 141.23 139.78L133.315 168.609C133.217 168.965 133.649 169.226 133.919 168.973L170.559 134.628C170.833 134.372 171.269 134.643 171.16 135.002L162.315 164.273C162.269 164.427 162.328 164.592 162.46 164.682L176.211 174.021'
        stroke='white' strokeWidth='2.22049' />
      <path fillRule='evenodd' clipRule='evenodd'
            d='M173.877 178.105C176.03 179.568 178.954 179.02 180.406 176.882C181.858 174.744 181.289 171.825 179.136 170.362C176.982 168.899 174.058 169.446 172.606 171.584C171.154 173.723 171.723 176.642 173.877 178.105Z'
            fill='white' />
    </svg>
  )
}

export default HeartIcon
