import React, { ReactNode } from 'react'
import { Wrapper, Title } from './styled'
import { FlexContainer } from '../UI/Flex'
import Text from '../UI/Text'
import ArrowUp from '../../assets/ArrowUp'
import { css } from 'styled-components'

type PriceChartProps = {
  title: string
  balanceTitle: string
  updatedTime: string
  balance: string
  children: ReactNode
}
const PriceChart = ({
  title,
  balanceTitle,
  updatedTime,
  balance,
  children,
}: PriceChartProps) => {
  return (
    <Wrapper>
      <FlexContainer width={'100%'} align={'center'} justify={'space-between'}>
        <Title>
          <Text fontSize={12} fontWeight={600}>
            {title}
          </Text>
        </Title>
        <Text fontSize={14} fontWeight={400} color={'rgba(0, 0, 0, .43)'}>
          Updated {updatedTime} min ago
        </Text>
      </FlexContainer>
      <FlexContainer align={'flex-start'} justify={'flex-start'} gap={'10px'}>
        <FlexContainer
          direction={'column'}
          align={'flex-start'}
          justify={'flex-start'}
          gap={'10px'}
        >
          <Text fontSize={40} fontWeight={500} customStyle={css`line-height: 30px`}>
            ${balance}
          </Text>
          <Text fontSize={14} fontWeight={500} color={'rgba(0, 0, 0, .47)'}>
            {balanceTitle}
          </Text>
        </FlexContainer>
        <FlexContainer align={'center'} justify={'flex-start'} gap={'4px'}>
          <ArrowUp />
          <Text fontSize={14} fontWeight={500}>
            +3,51 %
          </Text>
        </FlexContainer>
      </FlexContainer>
      {children}
    </Wrapper>
  )
}

export default PriceChart
