import styled, { CSSProp } from 'styled-components'

export const StyledInputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
`
export const StyledInput = styled.input<{
  fragment?: CSSProp
  isErrorShown: boolean
}>`
  width: 100%;
  box-sizing: border-box;
  padding: 12px 12px 12px 67px;
  border-radius: 8px;
  border: 1px solid #D0D5DD;
  color: #282827;
  background: #fff;
  outline: none;
  font-family: 'Poppins', system-ui;
  font-size: 18px;
  font-weight: 500;
  transition: all .3s ease;

  &::-webkit-inner-spin-button {
    display: none;
  }
  
  ${({ fragment }) => fragment};
`
export const UsdtText = styled.div`
  position: absolute;
  left: 12px;
  top: 13px;
`
export const SubtitleWrapper = styled.div`
  width: 100%;
  height: 40px;
`
