import React, { ChangeEvent, useState } from 'react'
import { CSSProp } from 'styled-components'
import Text from '../Text'
import { StyledInputWrapper, StyledInput, UsdtText, SubtitleWrapper } from './styled'

type InputProps = {
  onChange: (newValue: string) => void
  value: string
  placeholder: string
  isValueValid: boolean
  type?: string
  invalidMessage?: string
  fragment?: CSSProp
  subtitle?: string
}

const Input = (props: InputProps) => {
  const {
    onChange,
    fragment,
    type,
    invalidMessage,
    placeholder,
    isValueValid,
    value,
    subtitle,
  } = props

  const [isValueChanged, setIsValueChanged] = useState<boolean>(false)
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    setIsValueChanged(newValue !== '')
    onChange(newValue)
  }

  return (
    <StyledInputWrapper>
      <UsdtText>
        <Text fontSize={18} fontWeight={500} color={'#A7A6A1'}>USDT</Text>
      </UsdtText>
      <StyledInput
        isErrorShown={isValueChanged && !isValueValid}
        onChange={handleChange}
        value={value}
        placeholder={placeholder}
        type={type}
        fragment={fragment}
      />
      <SubtitleWrapper>
        {!isValueValid && isValueChanged && (
          <Text fontSize={14} fontWeight={500} color={'#E5484D'}>
            {invalidMessage}
          </Text>
        )}
        {!isValueChanged && (
          <Text fontSize={14} fontWeight={500} color={'#889096'}>
            {subtitle}
          </Text>
        )}
      </SubtitleWrapper>
    </StyledInputWrapper>
  )
}

export default Input
