import React from 'react'

const TransactionErrorIcon = () => {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='32' height='32' rx='16' fill='#DB4324' />
      <path
        d='M15.0708 18.84L14.6868 10.656L14.6148 7.92H17.3988L17.3028 10.656L16.9188 18.84H15.0708ZM16.0068 24.288C15.5108 24.288 15.0868 24.112 14.7348 23.76C14.3828 23.408 14.2068 22.96 14.2068 22.416C14.2068 21.872 14.3828 21.424 14.7348 21.072C15.0868 20.72 15.5108 20.544 16.0068 20.544C16.5188 20.544 16.9428 20.72 17.2788 21.072C17.6148 21.424 17.7828 21.872 17.7828 22.416C17.7828 22.96 17.6148 23.408 17.2788 23.76C16.9428 24.112 16.5188 24.288 16.0068 24.288Z'
        fill='#E6E8EB' />
    </svg>
  )
}

export default TransactionErrorIcon
