import React from 'react'
import { DotWrapper } from './styled'

type CustomDot = {
  cx?: number
  cy?: number
  type: 'sell' | 'buy'
}
const CustomDot = (props: CustomDot) => {
  const { cx, cy, type } = props

  if (cx === undefined || cy === undefined) {
    return null
  }

  return (
    <foreignObject x={cx - 19.5} y={cy - 9.5} width="39" height="19">
      <DotWrapper isGreen={type === 'buy'}>A91</DotWrapper>
    </foreignObject>
  )
}

export default CustomDot
