import React from 'react'
import BuyNFT from './components/BuyNFT'
import { FlexContainer } from '../../components/UI/Flex'
import PriceChart from '../../components/PriceChart'
import RiskAndProfitIndex from '../../components/RiskAndProfitIndex'
import TokenPriceChart from './components/TokenPriceChart'
import { css } from "styled-components";
import { phoneScreen } from "../../types/media";

const fakeData = [
  {
    name: 'JAN',
    uv: 4000,
  },
  {
    name: 'FEB',
    uv: 3000,
  },
  {
    name: 'MAR',
    uv: 2000,
  },
  {
    name: 'APR',
    uv: 2780,
  },
  {
    name: 'MAY',
    uv: 1890,
  },
  {
    name: 'JUN',
    uv: 2390,
  },
  {
    name: 'JUL',
    uv: 3490,
  },
]

const Main = () => {

  return (
    <FlexContainer direction={'column'} gap={'32px'} width={'100%'} padding={'57px 0'}>
      <BuyNFT />
      <FlexContainer
        width={'100%'}
        align={'center'}
        justify={'space-between'}
        gap="16px"
        customStyle={css`
          @media (max-width: ${phoneScreen}) {
            flex-direction: column;
            gap: 32px;
          }
        `}
      >
        <PriceChart
          title={'APYROLL TOKEN PRICE'}
          balanceTitle={'Current balance'}
          balance={'12.89'}
          updatedTime={'10'}
        >
          <TokenPriceChart data={fakeData} />
        </PriceChart>
        <RiskAndProfitIndex />
      </FlexContainer>
    </FlexContainer>
  )
}

export default Main
