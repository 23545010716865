import styled, { CSSProp } from 'styled-components'
import { phoneScreen, tabletScreen } from '../../../types/media'
export const CustomText = styled.span<{
  color: string
  fontSize: number
  fontWeight: number
  css: CSSProp
  isMedia?: boolean
}>`
  font-family: 'Poppins', system-ui;
	color: ${props => props.color};
  font-size: ${props => props.fontSize}px;
  font-weight: ${props => props.fontWeight};

  ${props => props.css}

  @media (max-width: ${tabletScreen}) {
    font-size: ${props => props.fontSize / 1.1}px;
  }
  
  @media (max-width: ${phoneScreen}) {
    font-size: ${props => props.fontSize / 1.2}px;
  }
`
