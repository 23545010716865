import styled from 'styled-components'
import { phoneScreen } from '../../types/media'

export const Wrapper = styled.div<{ variant: 'onboarding' | 'main' }>`
  position: absolute;
  top: ${p => (p.variant === 'onboarding' ? 16 : 55)}px;
  left: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
`
export const Circle = styled.div<{ background: string }>`
  width: 88px;
  height: 88px;
  border-radius: 50%;
  background: ${p => p.background};

  @media (max-width: ${phoneScreen}) { 
    width: 66px;
    height: 66px;
  }
`
