import React from 'react'

const EthereumIcon = () => {
  return (
    <svg width='290' height='290' viewBox='0 0 290 290' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path opacity='0.1'
            d='M86.2366 231.045C133.864 263.394 198.697 251.008 231.045 203.381C263.394 155.753 251.008 90.9202 203.38 58.5719C155.753 26.2235 90.92 38.6095 58.5717 86.2368C26.2233 133.864 38.6094 198.697 86.2366 231.045Z'
            fill='#3AB83A' />
      <mask id='mask0_99_4423' maskUnits='userSpaceOnUse' x='40' y='40' width='210'
            height='210'>
        <path
          d='M86.2366 231.045C133.864 263.394 198.697 251.008 231.045 203.381C263.394 155.753 251.008 90.9202 203.38 58.5719C155.753 26.2235 90.92 38.6095 58.5717 86.2368C26.2233 133.864 38.6094 198.697 86.2366 231.045Z'
          fill='white' />
      </mask>
      <g mask='url(#mask0_99_4423)'>
        <path fillRule='evenodd' clipRule='evenodd'
              d='M127.945 126.822L161.55 127.502L174.316 158.455L179.658 100.623L127.945 126.822ZM125.861 131.802L158.357 132.256L170.541 161.989L138.339 161.757L125.861 131.802ZM122.095 135.435C126.632 146.357 131.355 157.772 134.998 166.595L168.53 166.973C149.726 176.645 134.068 184.693 116.682 193.562C117.042 189.684 117.409 185.725 117.779 181.735L117.783 181.686C119.264 165.724 120.79 149.263 122.095 135.435Z'
              fill='#3AB83A' />
        <path fillRule='evenodd' clipRule='evenodd'
              d='M179.664 100.627L161.542 127.496L174.316 158.455L179.664 100.627ZM158.346 132.249L170.676 162.081L138.326 161.749L158.346 132.249ZM134.991 166.591L168.531 166.975C150.926 177.46 116.675 193.558 116.675 193.558L134.991 166.591Z'
              fill='#0B8311' />
      </g>
    </svg>
  )
}

export default EthereumIcon
