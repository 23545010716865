import { useAccount, useReadContract, useReadContracts } from 'wagmi'
import { ApyAbi } from '../../../contracts/Apy'
import { contractsAddresses } from '../../../contstants/contractsAddresses'
import { useEffect, useState } from 'react'
import { NftType } from '../../../types/nft'

type UserNftsResponse = {
  result: {
    nftInfo: NftType
    rewardDept: bigint
  }
}

export const useUserNfts = () => {
  const { address } = useAccount()
  const [nftIds, setNftIds] = useState<bigint[]>([])

  const userActiveNftsIds = useReadContract({
    abi: ApyAbi,
    address: contractsAddresses.apy,
    functionName: 'getActiveNFTs',
    args: [address || '0x1'],
  })

  useEffect(() => {
    if (userActiveNftsIds) {
      setNftIds(userActiveNftsIds.data as bigint[])
    }
  }, [userActiveNftsIds])

  const nftContracts = nftIds?.map(id => ({
    abi: ApyAbi,
    address: contractsAddresses.apy,
    functionName: 'getNftInfo',
    args: [id],
  }))

  const { data: userNftsData } = useReadContracts({
    contracts: nftContracts,
  })
  // @ts-ignore
  const userNfts: NftType[] = userNftsData ? userNftsData.map((nft: UserNftsResponse, index) => {
    const { amountUSDT, liquidity, stakingId, checkOut } = nft.result.nftInfo
    return {
      nftId: nftIds[index],
      amountUSDT,
      liquidity,
      stakingId,
      checkOut,
      rewards: nft.result.rewardDept,
    }
  }) : []

  return { userNfts }
}
