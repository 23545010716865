import React, { ReactNode } from 'react'
import { Container, MainContent, Wrapper } from './styled'
import { createGlobalStyle } from 'styled-components'
import Header from '../components/Header'
import Footer from '../components/Footer'
import NiceModal from '@ebay/nice-modal-react'

const { Provider: NiceModalProvider } = NiceModal

type LayoutProps = {
  children: ReactNode
}

const GlobalStyles = createGlobalStyle`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: 'Poppins', system-ui;
    position: relative;
    background: #F1F3F5;
  }
`

const Layout = ({ children }: LayoutProps) => {
  return (
    <Container>
      <NiceModalProvider>
        <Wrapper>
          <GlobalStyles />
          <Header />
          <MainContent>{children}</MainContent>
          <Footer />
        </Wrapper>
      </NiceModalProvider>
    </Container>
  )
}

export default Layout
