import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 28px;

  border-radius: 32px;
  background: #ffffff;
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding: 2px 8px;
  background: #ECEEF0;
  border-radius: 100px;
  text-transform: uppercase;
`
export const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`

export const TableWrapper = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: none;
`
export const TRStyled = styled.tr`
  &:nth-child(even) {
    background-color: #ffffff;
  }
  &tr:hover {
    background-color: #ddd;
  }

  background-color: #F8F9FA;
  border-bottom: 2px solid #F1F3F5;
`
export const THStyled = styled.th`
  padding: 12px 24px;
  background-color: #F8F9FA;

  color: #7E868C;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: start;

  &:last-child {
    text-align: end;
  }
`
export const TDStyled = styled.td`
  padding: 24px;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #11181C;

  &:nth-child(even) {
    color: #687076;
  }

  &:last-child {
    text-align: end;
  }
`

const mediaQuery = `
  @media (max-width: 768px) {
    ${Title} {
      font-size: 10px;
    }
    ${THStyled}, ${TDStyled} {
      padding: 12px;
      font-size: 14px;
    }
  }
`

export const styles = styled.div`
  ${mediaQuery}
`
