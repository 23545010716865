import React from 'react'
import { FlexContainer } from '../../components/UI/Flex'
import Claim from './components/Claim'
import Withdraw from './components/Withdraw'
import { useNavigate } from 'react-router-dom'
import ApyChart from './components/ApyChart'
import RiskAndProfitIndex from '../../components/RiskAndProfitIndex'
import TableTransactions from './components/TableTransactions'
import PriceChart from '../../components/PriceChart'
import { css } from 'styled-components'
import { tabletScreen } from '../../types/media'
import { useUserNfts } from './hooks/useUserNfts'
import { NftType } from '../../types/nft'
import { BuyMoreNFT, CardWrapper } from './styled'
import ArrowUp from '../../assets/ArrowUp'
import Button from '../../components/UI/Button'
import Text from '../../components/UI/Text'

const fakeData = [
  {
    name: 'JAN',
    uv: 50,
  },
  {
    name: 'FEB',
    uv: 70,
  },
  {
    name: 'MAR',
    uv: 20,
  },
  {
    name: 'APR',
    uv: 40,
  },
]

const Dashboard = () => {
  const navigate = useNavigate()

  const { userNfts } = useUserNfts()

  const renderStatus = (nft: NftType) => {
    if (nft.checkOut) return <Withdraw nft={nft} />
    return <Claim nft={nft} />
  }
  const navigateToBuyNFT = () => {
    navigate('/financial-nft', { replace: true })
  }

  return (
    <FlexContainer direction={'column'} gap={'32px'} width={'100%'} padding={'56px 0'}>
      {userNfts.length ?
        <FlexContainer direction={'row'} gap={'8px'} wrap={'wrap'}>
          {userNfts.map(nft => (
            <div key={nft.stakingId}>
              {renderStatus(nft)}
            </div>
          ))}
        </FlexContainer>
        :
        <BuyMoreNFT>
          <CardWrapper>
            <FlexContainer align={'center'} justify={'flex-start'} gap={'4px'}>
              <ArrowUp />
              <Text fontSize={14} fontWeight={500}>
                +3,51 %
              </Text>
            </FlexContainer>
            <Text
              fontSize={42}
              fontWeight={500}
              color={'#282828'}
              customStyle={css`line-height: 56px;`}
            >
              Unlock Exclusive Investment Opportunities with Our Utility NFTs
            </Text>
            <Text
              fontSize={16}
              fontWeight={500}
              color={'#00000090'}
              customStyle={css`line-height: 24px;`}
            >
              Purchase our Utility NFTs and gain access to innovative investment
              strategies with attractive APYs. Benefit from a transparent, low-fee
              structure and the potential for substantial profits.
            </Text>

            <Button
              onClick={navigateToBuyNFT}
              customStyle={{
                marginTop: '16px',
                width: 'max-content',
                padding: '0 28px',
                background: '#ffffff',
                color: '#000000',
                fontSize: '16px',
              }}
            >
              Buy more NFT
            </Button>
          </CardWrapper>
        </BuyMoreNFT>
      }
      <PriceChart
        title={'APY GRAPH'}
        balanceTitle={'Current APY'}
        balance={'12.89'}
        updatedTime={'10'}
      >
        <ApyChart data={fakeData} />
      </PriceChart>
      <FlexContainer
        justify={'space-between'}
        gap={'32px'}
        width={'100%'}
        align='center'
        customStyle={css`
          @media screen and (max-width: ${tabletScreen}) {
            flex-direction: column;
          }
        `}
      >
        <TableTransactions />
        <RiskAndProfitIndex />
      </FlexContainer>
    </FlexContainer>
  )
}

export default Dashboard
