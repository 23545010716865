import styled from 'styled-components'
import { tabletScreen } from '../../types/media'
export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  max-width: 662px;
  height: 788px;
  background: #fff;
  border-radius: 32px;
  overflow: hidden;
  padding: 16px;

  @media (max-width: ${tabletScreen}) {
    height: 658px;
  }
`
export const TextsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: #F1F3F5;
  padding: 32px;
  width: 100%;
  height: max-content;
  min-height: 350px;
  z-index: 2;
  border-radius: 24px;
`
