export const shortenWalletAddress = (address: string, length: number = 10): string => {
  if (!address.startsWith("0x") || address.length <= length * 2) {
    return address;
  }

  const startPart = address.slice(0, length);
  const endPart = address.slice(-length);

  return `${startPart}...${endPart}`;
}
