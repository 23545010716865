export const UsdtAbi = [{
  'inputs': [{ 'internalType': 'string', 'name': 'tokenURI_', 'type': 'string' }],
  'stateMutability': 'nonpayable',
  'type': 'constructor',
}, {
  'anonymous': false,
  'inputs': [{ 'indexed': true, 'internalType': 'address', 'name': 'owner', 'type': 'address' }, {
    'indexed': true,
    'internalType': 'address',
    'name': 'spender',
    'type': 'address',
  }, { 'indexed': false, 'internalType': 'uint256', 'name': 'value', 'type': 'uint256' }],
  'name': 'Approval',
  'type': 'event',
}, {
  'anonymous': false,
  'inputs': [{
    'indexed': true,
    'internalType': 'address',
    'name': 'previousOwner',
    'type': 'address',
  }, { 'indexed': true, 'internalType': 'address', 'name': 'newOwner', 'type': 'address' }],
  'name': 'OwnershipTransferred',
  'type': 'event',
}, {
  'anonymous': false,
  'inputs': [{ 'indexed': true, 'internalType': 'address', 'name': 'from', 'type': 'address' }, {
    'indexed': true,
    'internalType': 'address',
    'name': 'to',
    'type': 'address',
  }, { 'indexed': false, 'internalType': 'uint256', 'name': 'value', 'type': 'uint256' }],
  'name': 'Transfer',
  'type': 'event',
}, {
  'inputs': [],
  'name': 'INITIAL_SUPPLY',
  'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'address', 'name': 'owner', 'type': 'address' }, {
    'internalType': 'address',
    'name': 'spender',
    'type': 'address',
  }],
  'name': 'allowance',
  'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'address', 'name': 'spender', 'type': 'address' }, {
    'internalType': 'uint256',
    'name': 'amount',
    'type': 'uint256',
  }],
  'name': 'approve',
  'outputs': [{ 'internalType': 'bool', 'name': '', 'type': 'bool' }],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'address', 'name': 'account', 'type': 'address' }],
  'name': 'balanceOf',
  'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [],
  'name': 'decimals',
  'outputs': [{ 'internalType': 'uint8', 'name': '', 'type': 'uint8' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'address', 'name': 'spender', 'type': 'address' }, {
    'internalType': 'uint256',
    'name': 'subtractedValue',
    'type': 'uint256',
  }],
  'name': 'decreaseAllowance',
  'outputs': [{ 'internalType': 'bool', 'name': '', 'type': 'bool' }],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'address', 'name': 'spender', 'type': 'address' }, {
    'internalType': 'uint256',
    'name': 'addedValue',
    'type': 'uint256',
  }],
  'name': 'increaseAllowance',
  'outputs': [{ 'internalType': 'bool', 'name': '', 'type': 'bool' }],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [],
  'name': 'name',
  'outputs': [{ 'internalType': 'string', 'name': '', 'type': 'string' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [],
  'name': 'owner',
  'outputs': [{ 'internalType': 'address', 'name': '', 'type': 'address' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [],
  'name': 'renounceOwnership',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'string', 'name': 'tokenURI_', 'type': 'string' }],
  'name': 'setTokenURI',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [],
  'name': 'symbol',
  'outputs': [{ 'internalType': 'string', 'name': '', 'type': 'string' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [],
  'name': 'tokenURI',
  'outputs': [{ 'internalType': 'string', 'name': '', 'type': 'string' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [],
  'name': 'totalSupply',
  'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'address', 'name': 'to', 'type': 'address' }, {
    'internalType': 'uint256',
    'name': 'amount',
    'type': 'uint256',
  }],
  'name': 'transfer',
  'outputs': [{ 'internalType': 'bool', 'name': '', 'type': 'bool' }],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'address', 'name': 'from', 'type': 'address' }, {
    'internalType': 'address',
    'name': 'to',
    'type': 'address',
  }, { 'internalType': 'uint256', 'name': 'amount', 'type': 'uint256' }],
  'name': 'transferFrom',
  'outputs': [{ 'internalType': 'bool', 'name': '', 'type': 'bool' }],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'address', 'name': 'newOwner', 'type': 'address' }],
  'name': 'transferOwnership',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function',
}] as const
