import styled from 'styled-components'
import { phoneScreen, smallPhoneScreen } from "../../types/media";

export const Wrapper = styled.div`
  position: relative;
  z-index: 10;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 16px;
  height: 68px;
  padding: 12px 16px;

  @media screen and (max-width: ${phoneScreen}) {
    padding: 8px 12px;
  }
  @media screen and (max-width: ${smallPhoneScreen}) {
    padding: 6px 8px;
    height: 60px;
  }
`
export const LogoImg = styled.img`
  width: 150px;
  height: 44px;

  @media screen and (max-width: ${smallPhoneScreen}) {
    width: 120px;
    height: 36px;
  }
`
export const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 147px;

  @media screen and (max-width: ${phoneScreen}) {
    width: max-content;
  }
  @media screen and (max-width: ${smallPhoneScreen}) {
    max-width: 100px;
  }
`
