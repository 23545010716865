import React from 'react'
import { Wrapper, Title, ChartWrapper, ArrowAndChartWrapper, ArrowWrapper, BulletsWrapper, Bullet, Dot } from './styled'
import RiskAndProfitIndexChart from '../../assets/static/RiskAndProfitIndex.svg'
import Text from '../UI/Text'
import { FlexContainer } from '../UI/Flex'
import RiskAndProfitIndexArrow from '../../assets/RiskAndProfitIndexArrow'

const bullets = [
  { id: 1, color: '#FF3131', title: 'High Risk', value: '1% - 30%' },
  { id: 2, color: '#FA531C', title: 'Risk', value: '31% - 49%' },
  { id: 3, color: '#FFC700', title: 'Profit', value: '50% - 70%' },
  { id: 4, color: '#1CD54F', title: 'High Profit', value: '71% - 99%' },
]

const RiskAndProfitIndex = () => {
  return (
    <Wrapper>
      <Title>
        <Text fontSize={12} fontWeight={600} color={'#FA531C'}>RISK VS PROFIT INDEX</Text>
      </Title>
      <ArrowAndChartWrapper>
        <img src={RiskAndProfitIndexChart} alt='' />
        <ArrowWrapper>
          <RiskAndProfitIndexArrow />
        </ArrowWrapper>
      </ArrowAndChartWrapper>
      <ChartWrapper>
        <FlexContainer direction={'column'}>
          <FlexContainer width={'100%'} align={'center'} justify={'space-between'}>
            <Text fontSize={14} fontWeight={500} color={'#889096'}>High Risk</Text>
            <Text fontSize={14} fontWeight={500} color={'#889096'}>High Profit</Text>
          </FlexContainer>
          <Text fontSize={40} fontWeight={500}>59.3%</Text>
        </FlexContainer>
      </ChartWrapper>
      <BulletsWrapper>
        {bullets.map(bullet => (
          <Bullet key={bullet.id}>
            <FlexContainer align={'center'} justify={'flex-start'} gap={'8px'}>
              <Dot background={bullet.color} />
              <Text fontSize={14} fontWeight={500}>{bullet.title}</Text>
            </FlexContainer>
            <Text fontSize={14} fontWeight={500}>{bullet.value}</Text>
          </Bullet>
        ))}
      </BulletsWrapper>
    </Wrapper>
  )
}

export default RiskAndProfitIndex
