import React from 'react'

const TransactionIcon = () => {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='32' height='32' rx='16' fill='#161616' />
      <path
        d='M14.9597 21.624V17.088H10.6637V15.072H14.9597V10.536H17.0477V15.072H21.3437V17.088H17.0477V21.624H14.9597Z'
        fill='#CFFE56' />
    </svg>
  )
}

export default TransactionIcon
