import styled, { css, CSSProp } from 'styled-components'

export const StyledButton = styled.button<{ customStyle?: CSSProp, type: 'main' | 'secondary' | 'bordered' }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  background: #000000;
  color: #fff;
  border-radius: 40px;
  outline: none;
  border: none;
  transition: all .3s ease;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Poppins', system-ui;
  
  &:disabled {
    background: rgba(0, 0, 0, 0.03);
    color: rgba(0, 0, 0, 0.44);
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins', system-ui;
    pointer-events: none;
  }

  ${({ type }) => type === 'secondary' && css`
    background: transparent;
    color: #000;
  `}

  ${({ type }) => type === 'bordered' && css`
    background: transparent;
    color: #000;
    border: 2px solid #282828;
  `}

  ${({ customStyle }) => customStyle && customStyle}
`
