import React from 'react'
import BitcoinIcon from '../../assets/BitcoinIcon'
import { BitcoinIconWrapper, EthereumIconWrapper, EuroIconWrapper, HeartIconWrapper } from './styled'
import EthereumIcon from '../../assets/EthereumIcon'
import HeartIcon from '../../assets/HeartIcon'
import EuroIcon from '../../assets/EuroIcon'

const FirstScreenIcons = () => {
  return (
    <>
      <BitcoinIconWrapper>
        <BitcoinIcon />
      </BitcoinIconWrapper>
      <EthereumIconWrapper>
        <EthereumIcon />
      </EthereumIconWrapper>
      <HeartIconWrapper>
        <HeartIcon />
      </HeartIconWrapper>
      <EuroIconWrapper>
        <EuroIcon />
      </EuroIconWrapper>
    </>
  )
}

export default FirstScreenIcons
