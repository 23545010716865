export const ApyAbi = [{
  'inputs': [{
    'internalType': 'address',
    'name': '_poolAddress',
    'type': 'address',
  }, {
    'internalType': 'address',
    'name': '_nonfungiblePositionManager',
    'type': 'address',
  }, { 'internalType': 'address', 'name': '_pancakeRouter', 'type': 'address' }, {
    'internalType': 'address',
    'name': '_swapRouter',
    'type': 'address',
  }, { 'internalType': 'address', 'name': '_tokenAPYROLL', 'type': 'address' }, {
    'internalType': 'address',
    'name': '_tokenUSDT',
    'type': 'address',
  }, { 'internalType': 'address', 'name': '_companyWallet', 'type': 'address' }, {
    'internalType': 'address',
    'name': '_stakingContract',
    'type': 'address',
  }, { 'internalType': 'string', 'name': '_tokenURI', 'type': 'string' }],
  'stateMutability': 'nonpayable',
  'type': 'constructor',
}, {
  'anonymous': false,
  'inputs': [{ 'indexed': true, 'internalType': 'address', 'name': 'owner', 'type': 'address' }, {
    'indexed': true,
    'internalType': 'address',
    'name': 'approved',
    'type': 'address',
  }, { 'indexed': true, 'internalType': 'uint256', 'name': 'tokenId', 'type': 'uint256' }],
  'name': 'Approval',
  'type': 'event',
}, {
  'anonymous': false,
  'inputs': [{ 'indexed': true, 'internalType': 'address', 'name': 'owner', 'type': 'address' }, {
    'indexed': true,
    'internalType': 'address',
    'name': 'operator',
    'type': 'address',
  }, { 'indexed': false, 'internalType': 'bool', 'name': 'approved', 'type': 'bool' }],
  'name': 'ApprovalForAll',
  'type': 'event',
}, {
  'anonymous': false,
  'inputs': [{
    'indexed': false,
    'internalType': 'uint256',
    'name': '_fromTokenId',
    'type': 'uint256',
  }, { 'indexed': false, 'internalType': 'uint256', 'name': '_toTokenId', 'type': 'uint256' }],
  'name': 'BatchMetadataUpdate',
  'type': 'event',
}, {
  'anonymous': false,
  'inputs': [{ 'indexed': false, 'internalType': 'uint256', 'name': '_tokenId', 'type': 'uint256' }],
  'name': 'MetadataUpdate',
  'type': 'event',
}, {
  'anonymous': false,
  'inputs': [{
    'indexed': true,
    'internalType': 'address',
    'name': 'previousOwner',
    'type': 'address',
  }, { 'indexed': true, 'internalType': 'address', 'name': 'newOwner', 'type': 'address' }],
  'name': 'OwnershipTransferred',
  'type': 'event',
}, {
  'anonymous': false,
  'inputs': [{ 'indexed': true, 'internalType': 'address', 'name': 'from', 'type': 'address' }, {
    'indexed': true,
    'internalType': 'address',
    'name': 'to',
    'type': 'address',
  }, { 'indexed': true, 'internalType': 'uint256', 'name': 'tokenId', 'type': 'uint256' }],
  'name': 'Transfer',
  'type': 'event',
}, {
  'inputs': [{ 'internalType': 'uint256', 'name': 'tokenId', 'type': 'uint256' }, {
    'internalType': 'uint256',
    'name': 'amount0',
    'type': 'uint256',
  }, { 'internalType': 'uint256', 'name': 'amount1', 'type': 'uint256' }],
  'name': '_collectTokens',
  'outputs': [{ 'internalType': 'uint256', 'name': 'collected0', 'type': 'uint256' }, {
    'internalType': 'uint256',
    'name': 'collected1',
    'type': 'uint256',
  }],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'uint256', 'name': 'tokenId', 'type': 'uint256' }],
  'name': '_finalizeBurn',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'uint256', 'name': 'tokenId', 'type': 'uint256' }],
  'name': '_removeLiquidity',
  'outputs': [{ 'internalType': 'uint256', 'name': 'amount0', 'type': 'uint256' }, {
    'internalType': 'uint256',
    'name': 'amount1',
    'type': 'uint256',
  }],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'address', 'name': 'owner', 'type': 'address' }, {
    'internalType': 'uint256',
    'name': 'collected0',
    'type': 'uint256',
  }, { 'internalType': 'uint256', 'name': 'collected1', 'type': 'uint256' }],
  'name': '_swapAndSendTokens',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'address', 'name': 'owner', 'type': 'address' }, {
    'internalType': 'uint256',
    'name': 'tokenId',
    'type': 'uint256',
  }], 'name': '_unstakeNFT', 'outputs': [], 'stateMutability': 'nonpayable', 'type': 'function',
}, {
  'inputs': [],
  'name': 'activeSupply',
  'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'address', 'name': 'to', 'type': 'address' }, {
    'internalType': 'uint256',
    'name': 'tokenId',
    'type': 'uint256',
  }], 'name': 'approve', 'outputs': [], 'stateMutability': 'nonpayable', 'type': 'function',
}, {
  'inputs': [{ 'internalType': 'address', 'name': 'token', 'type': 'address' }, {
    'internalType': 'uint256',
    'name': 'amount',
    'type': 'uint256',
  }], 'name': 'approveTokenTransfer', 'outputs': [], 'stateMutability': 'nonpayable', 'type': 'function',
}, {
  'inputs': [{ 'internalType': 'address', 'name': 'owner', 'type': 'address' }],
  'name': 'balanceOf',
  'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [],
  'name': 'baseTokenURI',
  'outputs': [{ 'internalType': 'string', 'name': '', 'type': 'string' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'uint256', 'name': 'tokenId', 'type': 'uint256' }],
  'name': 'burnNFT',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'uint256', 'name': 'amountUSDT', 'type': 'uint256' }],
  'name': 'calculateDetails',
  'outputs': [{
    'components': [{
      'internalType': 'uint256',
      'name': 'amountToExchange',
      'type': 'uint256',
    }, { 'internalType': 'uint256', 'name': 'amountCore', 'type': 'uint256' }, {
      'internalType': 'uint256',
      'name': 'amountToTrader',
      'type': 'uint256',
    }, { 'internalType': 'uint256', 'name': 'amountToCompany', 'type': 'uint256' }, {
      'internalType': 'uint256',
      'name': 'amountToDeposit',
      'type': 'uint256',
    }, {
      'internalType': 'uint256',
      'name': 'amountToDepositLiquidity',
      'type': 'uint256',
    }, { 'internalType': 'uint256', 'name': 'amountOut', 'type': 'uint256' }],
    'internalType': 'struct ApyNFT.SwapDetails',
    'name': '',
    'type': 'tuple',
  }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'uint256', 'name': 'tokenId', 'type': 'uint256' }],
  'name': 'claimRewards',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [],
  'name': 'companyFee',
  'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [],
  'name': 'companyPercentage',
  'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [],
  'name': 'companyWallet',
  'outputs': [{ 'internalType': 'address', 'name': '', 'type': 'address' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'address', 'name': 'token', 'type': 'address' }, {
    'internalType': 'uint256',
    'name': 'amount',
    'type': 'uint256',
  }], 'name': 'depositRewards', 'outputs': [], 'stateMutability': 'nonpayable', 'type': 'function',
}, {
  'inputs': [],
  'name': 'exchangeRate',
  'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'uint256', 'name': 'amountIn', 'type': 'uint256' }],
  'name': 'executeSwap',
  'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'address', 'name': 'owner', 'type': 'address' }],
  'name': 'getActiveNFTs',
  'outputs': [{ 'internalType': 'uint256[]', 'name': '', 'type': 'uint256[]' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'uint256', 'name': 'tokenId', 'type': 'uint256' }],
  'name': 'getApproved',
  'outputs': [{ 'internalType': 'address', 'name': '', 'type': 'address' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'uint256', 'name': 'tokenId', 'type': 'uint256' }],
  'name': 'getNftInfo',
  'outputs': [{
    'components': [{
      'components': [{
        'internalType': 'uint256',
        'name': 'amountUSDT',
        'type': 'uint256',
      }, { 'internalType': 'uint256', 'name': 'liquidity', 'type': 'uint256' }, {
        'internalType': 'uint256',
        'name': 'stakingId',
        'type': 'uint256',
      }, { 'internalType': 'bool', 'name': 'checkOut', 'type': 'bool' }],
      'internalType': 'struct ApyNFT.NFTInfo',
      'name': 'nftInfo',
      'type': 'tuple',
    }, { 'internalType': 'uint256', 'name': 'rewardDept', 'type': 'uint256' }],
    'internalType': 'struct ApyNFT.NFTFullInfo',
    'name': '',
    'type': 'tuple',
  }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [],
  'name': 'getSqrtPriceLimitX96',
  'outputs': [{ 'internalType': 'uint160', 'name': '', 'type': 'uint160' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'address', 'name': 'owner', 'type': 'address' }, {
    'internalType': 'address',
    'name': 'operator',
    'type': 'address',
  }],
  'name': 'isApprovedForAll',
  'outputs': [{ 'internalType': 'bool', 'name': '', 'type': 'bool' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [],
  'name': 'lastId',
  'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [],
  'name': 'maxAmountUSDT',
  'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [],
  'name': 'minAmountUSDT',
  'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'uint256', 'name': 'amountUSDT', 'type': 'uint256' }],
  'name': 'mintNFT',
  'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'uint256', 'name': 'amountUSDT', 'type': 'uint256' }, {
    'internalType': 'uint256',
    'name': 'amountOut',
    'type': 'uint256',
  }],
  'name': 'mintNFTLiquidity',
  'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }, {
    'internalType': 'uint128',
    'name': '',
    'type': 'uint128',
  }, { 'internalType': 'uint256', 'name': '', 'type': 'uint256' }, {
    'internalType': 'uint256',
    'name': '',
    'type': 'uint256',
  }],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [],
  'name': 'name',
  'outputs': [{ 'internalType': 'string', 'name': '', 'type': 'string' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'name': 'nftInfo',
  'outputs': [{ 'internalType': 'uint256', 'name': 'amountUSDT', 'type': 'uint256' }, {
    'internalType': 'uint256',
    'name': 'liquidity',
    'type': 'uint256',
  }, { 'internalType': 'uint256', 'name': 'stakingId', 'type': 'uint256' }, {
    'internalType': 'bool',
    'name': 'checkOut',
    'type': 'bool',
  }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [],
  'name': 'nonfungiblePositionManager',
  'outputs': [{ 'internalType': 'address', 'name': '', 'type': 'address' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [],
  'name': 'owner',
  'outputs': [{ 'internalType': 'address', 'name': '', 'type': 'address' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'uint256', 'name': 'tokenId', 'type': 'uint256' }],
  'name': 'ownerOf',
  'outputs': [{ 'internalType': 'address', 'name': '', 'type': 'address' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [],
  'name': 'pancakeRouter',
  'outputs': [{ 'internalType': 'address', 'name': '', 'type': 'address' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [],
  'name': 'poolAddress',
  'outputs': [{ 'internalType': 'address', 'name': '', 'type': 'address' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'address', 'name': 'owner', 'type': 'address' }, {
    'internalType': 'uint256',
    'name': 'tokenId',
    'type': 'uint256',
  }], 'name': 'removeNFTFromOwner', 'outputs': [], 'stateMutability': 'nonpayable', 'type': 'function',
}, {
  'inputs': [],
  'name': 'renounceOwnership',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'name': 'rewards',
  'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'address', 'name': 'from', 'type': 'address' }, {
    'internalType': 'address',
    'name': 'to',
    'type': 'address',
  }, { 'internalType': 'uint256', 'name': 'tokenId', 'type': 'uint256' }],
  'name': 'safeTransferFrom',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'address', 'name': 'from', 'type': 'address' }, {
    'internalType': 'address',
    'name': 'to',
    'type': 'address',
  }, { 'internalType': 'uint256', 'name': 'tokenId', 'type': 'uint256' }, {
    'internalType': 'bytes',
    'name': 'data',
    'type': 'bytes',
  }], 'name': 'safeTransferFrom', 'outputs': [], 'stateMutability': 'nonpayable', 'type': 'function',
}, {
  'inputs': [{ 'internalType': 'address', 'name': 'operator', 'type': 'address' }, {
    'internalType': 'bool',
    'name': 'approved',
    'type': 'bool',
  }], 'name': 'setApprovalForAll', 'outputs': [], 'stateMutability': 'nonpayable', 'type': 'function',
}, {
  'inputs': [{ 'internalType': 'string', 'name': 'newbaseTokenURI', 'type': 'string' }],
  'name': 'setBaseTokenURI',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'uint256', 'name': '_tokenId', 'type': 'uint256' }, {
    'internalType': 'bool',
    'name': 'status',
    'type': 'bool',
  }], 'name': 'setCheckOut', 'outputs': [], 'stateMutability': 'nonpayable', 'type': 'function',
}, {
  'inputs': [{ 'internalType': 'uint256', 'name': '_companyFee', 'type': 'uint256' }],
  'name': 'setCompanyFee',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'uint256', 'name': 'newFee', 'type': 'uint256' }],
  'name': 'setCompanyPercantage',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'address', 'name': '_companyWallet', 'type': 'address' }],
  'name': 'setCompanyWallet',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'uint256', 'name': 'newRate', 'type': 'uint256' }],
  'name': 'setExchangeRate',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'uint256', 'name': 'maxUsdt', 'type': 'uint256' }],
  'name': 'setMaxUsdt',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'uint256', 'name': 'minUsdt', 'type': 'uint256' }],
  'name': 'setMinUsdt',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'address', 'name': '_nonFungible', 'type': 'address' }],
  'name': 'setNonFungible',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'address', 'name': '_pancakeRouter', 'type': 'address' }],
  'name': 'setPancakeRouter',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'address', 'name': '_pool', 'type': 'address' }],
  'name': 'setPool',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'address', 'name': '_stakingContract', 'type': 'address' }],
  'name': 'setStakingContract',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'address', 'name': '_swapRouter', 'type': 'address' }],
  'name': 'setSwapROuter',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'uint256', 'name': 'swapRate', 'type': 'uint256' }],
  'name': 'setSwapRate',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'address', 'name': '_tokenAPYROLL', 'type': 'address' }],
  'name': 'setTokenAPYROLL',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'address', 'name': '_tokenUSDT', 'type': 'address' }],
  'name': 'setTokenUSDT',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'address', 'name': '_traderAddress', 'type': 'address' }],
  'name': 'setTraderAddress',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'uint256', 'name': '_traderFee', 'type': 'uint256' }],
  'name': 'setTraderFee',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [],
  'name': 'slippage',
  'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'uint256', 'name': 'tokenId', 'type': 'uint256' }, {
    'internalType': 'uint256',
    'name': '_amountUSDT',
    'type': 'uint256',
  }, { 'internalType': 'address', 'name': '_staker', 'type': 'address' }],
  'name': 'stakeNFT',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [],
  'name': 'stakingContract',
  'outputs': [{ 'internalType': 'address', 'name': '', 'type': 'address' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'bytes4', 'name': 'interfaceId', 'type': 'bytes4' }],
  'name': 'supportsInterface',
  'outputs': [{ 'internalType': 'bool', 'name': '', 'type': 'bool' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [],
  'name': 'swapRouter',
  'outputs': [{ 'internalType': 'address', 'name': '', 'type': 'address' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [],
  'name': 'swaprateUsdt',
  'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [],
  'name': 'symbol',
  'outputs': [{ 'internalType': 'string', 'name': '', 'type': 'string' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [],
  'name': 'tokenAPYROLL',
  'outputs': [{ 'internalType': 'address', 'name': '', 'type': 'address' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [],
  'name': 'tokenLP',
  'outputs': [{ 'internalType': 'address', 'name': '', 'type': 'address' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'uint256', 'name': 'tokenId', 'type': 'uint256' }],
  'name': 'tokenURI',
  'outputs': [{ 'internalType': 'string', 'name': '', 'type': 'string' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [],
  'name': 'tokenUSDT',
  'outputs': [{ 'internalType': 'address', 'name': '', 'type': 'address' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [],
  'name': 'totalActiveSupply',
  'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [],
  'name': 'totalSupply',
  'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [],
  'name': 'traderAddress',
  'outputs': [{ 'internalType': 'address', 'name': '', 'type': 'address' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [],
  'name': 'traderFee',
  'outputs': [{ 'internalType': 'uint256', 'name': '', 'type': 'uint256' }],
  'stateMutability': 'view',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'address', 'name': 'from', 'type': 'address' }, {
    'internalType': 'address',
    'name': 'to',
    'type': 'address',
  }, { 'internalType': 'uint256', 'name': 'tokenId', 'type': 'uint256' }],
  'name': 'transferFrom',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'address', 'name': 'newOwner', 'type': 'address' }],
  'name': 'transferOwnership',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function',
}, {
  'inputs': [{ 'internalType': 'address', 'name': 'token', 'type': 'address' }, {
    'internalType': 'uint256',
    'name': 'amount',
    'type': 'uint256',
  }, { 'internalType': 'address', 'name': 'withdrawTo', 'type': 'address' }],
  'name': 'withdrawExtraTokens',
  'outputs': [],
  'stateMutability': 'nonpayable',
  'type': 'function',
}] as const
