import React, { useState } from 'react'
import { FlexContainer } from '../../../../components/UI/Flex'
import { NFTWrapper, RenderCardWrapper, SmallCard } from '../../styled'
import PinnedIcon from '../../../../assets/PinnedIcon'
import Text from '../../../../components/UI/Text'
import { css } from 'styled-components'
import Button from '../../../../components/UI/Button'
import { NftType } from '../../../../types/nft'
import { getNumberFromBigInt } from '../../../../utils/formatBigInt'
import { useWriteContract } from 'wagmi'
import { ApyAbi } from '../../../../contracts/Apy'
import { contractsAddresses } from '../../../../contstants/contractsAddresses'
import Loader from '../../../../components/UI/Loader'
import { getTransactionReceipt } from '../../../../utils/getTransacationReceipt'
import TransactionModal from '../../../../components/Modals/TransactionModal'
import { TransactionStatus } from '../../../../types/transaction'

type WithdrawProps = {
  nft: NftType
}

const Withdraw = ({ nft }: WithdrawProps) => {
  const { writeContract } = useWriteContract()

  const [isTxLoading, setIsTxLoading] = useState(false)
  const handleWithdraw = () => {
    setIsTxLoading(true)
    writeContract(
      {
        abi: ApyAbi,
        address: contractsAddresses.apy,
        functionName: 'burnNFT',
        args: [BigInt(nft.nftId)],
      },
      {
        onSuccess: async data => {
          const transactionReceipt = await getTransactionReceipt(data)
          if (transactionReceipt.status === 'success') {
            setIsTxLoading(false)
          }
        },
        onError: async error => {
          setIsTxLoading(false)
        },
      },
    )
  }

  return (
    <NFTWrapper>
      <FlexContainer width={'100%'} direction={'column'} gap='24px' height='100%'>
        <RenderCardWrapper bgColor={'green'}>
          <FlexContainer
            width='100%'
            direction='column'
            justify='space-between'
            height='100%'
            gap='16px'
          >
            <FlexContainer width={'100%'} direction={'column'} gap='14px'>
              <FlexContainer
                width={'100%'}
                justify={'space-between'}
                align={'center'}
                gap='16px'
              >
                <PinnedIcon />
                <Text
                  fontSize={14}
                  fontWeight={500}
                  color='#161616'
                  customStyle={css`line-height: 20px;`}
                >
                  NFT A-91
                </Text>
              </FlexContainer>

              <SmallCard>
                <Text fontSize={14} fontWeight={500} color='#161616'>
                  Unlocked
                </Text>
              </SmallCard>
            </FlexContainer>

            <FlexContainer width={'100%'} direction='column' gap='4px'>
              <Text
                fontSize={40}
                fontWeight={500}
                customStyle={css`line-height: 56px;`}
              >
                {getNumberFromBigInt(nft.amountUSDT)}{' '}
                <Text
                  fontSize={24}
                  fontWeight={500}
                  customStyle={css`line-height: 32px;`}
                >
                  USDT
                </Text>
              </Text>
              <Text
                fontSize={14}
                fontWeight={500}
                color='#16161680'
                customStyle={css`line-height: 20px;`}
              >
                Total balance
              </Text>
            </FlexContainer>
          </FlexContainer>
        </RenderCardWrapper>

        <FlexContainer width={'100%'} direction={'column'} gap='16px'>
          <Button
            customStyle={css`border-radius: 12px`}
            onClick={handleWithdraw}
            disabled={isTxLoading}
          >
            {isTxLoading ? <Loader /> : 'Withdraw USDT'}
          </Button>
          <Button onClick={() => {}} type='secondary'>
            Resume
          </Button>
        </FlexContainer>
      </FlexContainer>
    </NFTWrapper>
  )
}

export default Withdraw
